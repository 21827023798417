import React, { useState, useEffect } from 'react';
import { Button, VStack, Text, Box, Flex, Checkbox, Link,
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalBody, ModalCloseButton, ModalFooter,useDisclosure } from '@chakra-ui/react';
import { getUrl } from 'aws-amplify/storage';

const DownloadPDF = () => {
  const [downloadUrl, setDownloadUrl] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const filename = 'DL資料_法人向けマインドフルネス研修.pdf'; 
    getFileUrl(filename).then(url => {
      setDownloadUrl(url);
    });
  }, []);

  async function getFileUrl(filename) {
    try {
        const getUrlResult = await getUrl({
            key: filename,
            options: {
                accessLevel: 'guest', 
                expiresIn: 180, 
                useAccelerateEndpoint: false, 
            },
        });
        return getUrlResult.url; 
    } catch (error) {
        console.error('Error getting file URL', error);
        return null;
    }
  }
  const handleDownloadClick = () => {
    if (!isChecked) {
      setIsModalOpen(true);
    } else {
      window.location.href = downloadUrl;
    }
  };
  
      return (
    <Flex height="50vh" alignItems="center" justifyContent="center"> 
      <Box justifyContent="center" width={["90%","40%"]} height={["250px","300px"]} backgroundColor="rgba(46, 177, 198, 0.2)">
        <VStack spacing={5}>
        <Text marginX={["20px", "100px"]} marginTop="50px" fontSize={["15px","20px"]}>
          <p style={{ textAlign: "center" }}>株式会社Lay</p>
          <p style={{ textAlign: "center" }}>法人向けマインドフルネス研修</p>
          <p style={{ textAlign: "center" }}>トライアルプログラム説明資料</p>
        </Text>
        {/* <Checkbox 
            isChecked={isChecked} 
            onChange={(e) => setIsChecked(e.target.checked)}
            colorScheme="blue" 
            sx={{ '.chakra-checkbox__control': { borderColor: 'blue.500', borderWidth: '2px' } }} 
            >
          <Text 
            fontSize={["12px","15px"]} 
            color="rgba(49, 130, 206, 1)"
            onClick={(e) => {
              e.stopPropagation();  // チェックボックスの状態変更を阻止
              onOpen();             // モーダルを開く
            }}
          >
              プライバシーポリシーに同意する
          </Text>
          </Checkbox> */}

          <Checkbox
                isChecked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                colorScheme="blue"
                sx={{ '.chakra-checkbox__control': { borderColor: 'blue.500', borderWidth: '2px' } }}
            >
                <Button
                    variant="ghost"
                    onClick={(e) => {
                        e.preventDefault(); // Prevent checkbox state change
                        onOpen(); // Open modal only
                    }}
                    _hover={{ bg: 'transparent' }}
                    _active={{ bg: 'transparent' }}
                    p={0}
                    minW="auto"
                >
                    <Text
                        fontSize={["12px", "15px"]}
                        color="rgba(49, 130, 206, 1)"
                        textDecoration="underline" 
                    >
                        プライバシーポリシーに同意する
                    </Text>
                </Button>
            </Checkbox>
          {downloadUrl && (
              <Button 
                onClick={handleDownloadClick}
                marginTop="20px" 
                bg={isChecked ? "rgba(46, 177, 198, 1)" : "gray.200"} // チェックされている場合は元の色、そうでなければ灰色に
                color="white"
                height={["30px", "40px"]}
                width={["100px", "150px"]}
              >
                <Text fontSize={["13px", "18px"]}>ダウンロード</Text>
              </Button>
          )}
        </VStack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
            <ModalContent>
              <ModalHeader>プライバシーポリシー</ModalHeader>
                <ModalCloseButton />
                  <ModalBody>
                    <iframe
                          src="https://lay-mindfulness.com/privacy_policy/"
                          width="100%"
                          height="400px"
                          style={{ border: 'none' }}
                          title="プライバシーポリシー"
                      />
                  </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                     閉じる
                </Button>
              </ModalFooter>
            </ModalContent>
      </Modal>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ダウンロード前に</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            プライバシーポリシーに同意してください。
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsModalOpen(false)}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default DownloadPDF;

