import React, { useState,useEffect } from 'react';
import { Box, Input, Button,useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CustomToast from "./PinVerficationCustomToast";

const PinVerification = ({ pinCode=1111 }) => {
    const [inputPin, setInputPin] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const toast = useToast();

    const handleChange = (event) => {
        const newPin = event.target.value.slice(0, 4); // 最大4桁まで
        setInputPin(newPin.replace(/[^0-9]/g, '')); // 数字以外を許可しない
    };

    useEffect(() => {
        onOpen(); // コンポーネントのマウント時にモーダルを開く
    }, [onOpen]); 

    const handleVerify = () => {
        if (parseInt(inputPin, 10) === parseInt(pinCode, 10)) {
            toast({
                // title: 'ありがとうございます。',
                // description: "最後まで楽しんでください。",
                // status: 'success',
                duration: 2500,
                isClosable: true,
                position:"top",
                onCloseComplete: () => navigate('/surveyOneMore'),
                render: () => (
                    <CustomToast
                        title="ありがとうございます。"
                        description="最後まで楽しんでください。"
                        status="success"
                />
                ),
            });
        } else {
            toast({
                // title: 'エラーが発生しました。',
                // description: "数字の確認をお願いします。",
                // status: 'error',
                duration: 5000,
                isClosable: true,
                position:"top",
                render: () => (
                    <CustomToast
                        title='エラーが発生しました。'
                        description="数字の確認をお願いします。"
                        status="error"
                />
                ),
            });
        }
    };

    return (
        <>
            {/* <Button onClick={onOpen}>PINコードを確認する</Button> */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign="center" fontSize={["12px", "18px"]}>4桁のPINコードを半角で入力してください</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Box display="flex" justifyContent="center">
                            <Input 
                                placeholder="----" 
                                maxLength="4" 
                                value={inputPin} 
                                onChange={handleChange} 
                                textAlign="center"
                                boarder="2px"
                                borderColor="#2eb1c6"
                                />
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button mt={4}
                                size={["sm", "sm"]}
                                backgroundColor="#2eb1c6" 
                                color="white"  
                                _hover={{ bg: "#1e949d", color: "white" }} // ホバー時の背景色と文字色
                                _active={{ bg: "#106b73", color: "white" }} // アクティブ（クリックされた時）の背景色と文字色
                                _disabled={{ bg: "#d3d3d3", color: "gray.200" }} // 無効時の背景色と文字色
                                onClick={handleVerify} 
                                isDisabled={inputPin.length < 4}
                            >
                                確認
                            </Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PinVerification;
