import { Box, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import DeskTopView from './DesktopView'
import MobileView from "./MobileView"

function TopPageView() {
  const { onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  return (
    <Box>
      {isMobile ? (
        <MobileView onOpen={onOpen} onClose={onClose} />
      ) : (
        <DeskTopView onOpen={onOpen} onClose={onClose} />
      )}
    </Box>
  );
}

export default TopPageView;