const sholderPainOptions = [
    { value: "ひどく痛む", label: "ひどく痛む"},
    { value: "少し痛む", label: "少し痛む",},
    { value: "ほとんど感じない", label: "ほとんど感じない",}
  ];
  
  const sleepConditionOptions = [
    { value: "あまり眠れない", label: "あまり眠れない", },
    { value: "夜中に時々起きてしまう", label: "夜中に時々起きてしまう", },
    { value: "よく眠れている", label: "よく眠れている", },
  ];
  
  const mentalConditionOptions = [
    { value: "すぐに怒ったりイライラする", label: "すぐに怒ったりイライラする"},
    { value: "怒りやイライラが起こるが表には出さない", label: "怒りやイライラが起こるが表には出さない"},
    { value: "ほどんと怒りやイライラを感じない", label: "ほとんど怒りやイライラを感じない"},
  ];

  const focusConditionOptions = [
    { value: "集中しようとしても出来ないことが多い", label: "集中しようとしても出来ないことが多い"},
    { value: "集中することは出来るが長続きしない", label: "集中することは出来るが長続きしない"},
    { value: "集中したい時は、集中することが出来る", label: "集中したい時は、集中することが出来る"},
  ];

  const relationshipOptions = [
    { value: "親しい人でも会うのが億劫に感じる", label: "親しい人でも会うのが億劫に感じる"},
    { value: "親しくない人に会うのは憂鬱に感じる", label: "親しくない人に会うのは憂鬱に感じる"},
    { value: "人と会うことを苦痛に感じない", label: "人と会うことを苦痛に感じない"},
  ];

  export {
    sholderPainOptions,
    sleepConditionOptions,
    mentalConditionOptions,
    focusConditionOptions,
    relationshipOptions
  };
