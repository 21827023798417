import React, { useState, useEffect } from 'react';
import { Box, Button, Text, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';

const CookieConsent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoaded, setIsLoaded] = useState(false);
  const cancelRef = React.useRef();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      const timer = setTimeout(onOpen, 1000); // Adjust time as needed
      setIsLoaded(true);
      return () => clearTimeout(timer);
    }
  }, [onOpen]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    onClose();
    // ここでクッキーを設定するコードを追加する
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            クッキーの使用
          </AlertDialogHeader>

          <AlertDialogBody>
            このウェブサイトはクッキーを使用しています。詳細については、当社のプライバシーポリシーをお読みください。
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              拒否
            </Button>
            <Button colorScheme="blue" onClick={handleAccept} ml={3}>
              同意する
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CookieConsent;
