import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import RequestFormForSurvey from './RequestFormForSurvey';

function RequestIntro() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleModalClose = () => {
    onClose();
    setFormSubmitted(true);  // モーダルを閉じた時にフォームが送信されたとマーク
  };

  const handleModalOpen = () => {
    onOpen();
    setFormSubmitted(true);  // モーダルを閉じた時にフォームが送信されたとマーク
  };

  const clickForSurveyOneMore = () => {
    if (formSubmitted) {
      navigate('/surveyOneMore');
    } else {
      onOpen();  // フォームがまだ送信されていない場合は、モーダルを開く
    }
  };

  return (
    <Flex justifyContent="center" direction="flex" alignItems="center">
      <Button
        onClick={handleModalOpen}
        size="lg"
        fontSize="20px"
        paddingTop="10px"
        paddingRight="15px"
        paddingBottom="10px"
        paddingLeft="15px"
        color="white"
        bg="#23A6BA"
        _hover={{ bg: "#2BB1C5" }}
        _active={{ bg: "#1E8A9D" }}
        marginTop={"60vh"}
        marginBottom={"30px"}
        borderRadius="0"
        border="none"
      >
        次へ進む
      </Button>

      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent size="xl">
          <ModalHeader>リクエストフォーム</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RequestFormForSurvey onSubmitted={handleModalClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default RequestIntro;
