// RouterComponent.js
import React, { useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import TopPage from "./TopPage";
import SurveyTop from './SurveyTop';
import SurveyBody from './SurveyBody';
import CookieConsent from './CookieConsent';
import ResultIntro from './ResultIntro';
import ResultRequest from './ResultRequest';
import RequestForm from './RequestForm';
import DownloadPDF from './DownloadPDF';
import SurveyOneMore from './SurveyOneMore';
import VoiceDesktop from './VoiceDesktop';
import VoiceMobile from './VoiceMobile';
import Analysis2 from "./Analysis2";
import PinVerification from './PinVerfication';
import usePageTime from './Tracking/usePageTime';
import { TrackingContext } from './App'; // もしくは適切なパスに修正

function RouterComponent() {
  const navigate = useNavigate();
  const { startTime, location } = usePageTime();
  const { addTracking } = useContext(TrackingContext);

  const handleNavigation = (path) => {
    const endTime = Date.now();
    const duration = (endTime - startTime) / 1000;
    console.log(`Navigating to ${path}. Previous page was ${location.pathname} with duration ${duration} seconds`);
    addTracking(location.pathname, duration);
    navigate(path);
  };

  return (
    <Routes>
      <Route path="/" element={<TopPage onNavigate={(path) => handleNavigation(path)} />} />
      <Route path="/surveyTop" element={<SurveyTop />} />
      <Route path="/surveyBody" element={<SurveyBody />} />
      <Route path="/cookie" element={<CookieConsent />} />
      <Route path="/resultIntro" element={<ResultIntro />} />
      <Route path="/resultRequest" element={<ResultRequest />} />
      <Route path="/requestForm" element={<RequestForm />} />
      <Route path="/surveyOneMore" element={<SurveyOneMore />} />
      <Route path="/downloadPdf" element={<DownloadPDF />} />
      <Route path="/pinVerification" element={<PinVerification />} />
      <Route path="/voiceDesktop" element={<VoiceDesktop />} />
      <Route path="/voiceMobile" element={<VoiceMobile />} />
      <Route path="/analysis" element={<Analysis2 />} />
    </Routes>
  );
}

export default RouterComponent;
