import { Chart, registerables } from "chart.js";
import { Radar } from 'react-chartjs-2';
import questionsData from "./Questions.json";
import feedbackData from "./Feedback.json";
import React, {useState, useEffect} from "react";
import { Text,Box} from "@chakra-ui/react";

Chart.register(...registerables)

const RaderChart2 = (props) => {


  console.log("propsNumber", props.number)
  const labels = props.number === 6
    ? ["肩こり・偏頭痛", "睡眠の状態", "こころの状態", "集中力の状態", "人間関係の状態"]
    : ["感覚を眺める", "反応しない", "判断しない", "描写と言語化", "行動の意識化"];

  const sugestedMax = props.number === 6 ? 3 : 5;

  const classifications = ["感覚を眺める", "反応しない", "判断しない", "描写と言語化", "行動の意識化"];
  const [thisTimeScore, setThisTimeScore] = useState([]);
  const [comparisonScore, setComparisonScore] = useState([]);
  const [scoreChanges, setScoreChanges] = useState([]);

  const explanationsArray = Object.values(feedbackData.explanation);
  
    const reverseFunc =(questionsData, answers, i) =>{
      if (questionsData[i].reverse===true){
        return Math.abs(answers[i]-6);
      } else if (questionsData[i].reverse===false){
        return answers[i];
    }}

    const makeScore = (answers)=>{
      console.log("questionsData", questionsData); 
      console.log("answers", answers)
      const scores = [0,0,0,0,0]
      for (let i=0; i < answers.length; i++){
        if (questionsData[i].classification=="感覚を眺める"){
          scores[0] = scores[0]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="反応しない"){
          scores[1] = scores[1]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="判断しない"){
          scores[2] = scores[2]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="描写と言語化"){
          scores[3] = scores[3]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="行動の意識化"){
          scores[4] = scores[4]+reverseFunc(questionsData, answers, i)
      }
    }
    const scores2 = scores.map(score=>Math.round(score/3*10)/10)
    return scores2
  }

  useEffect(() => {
    if (props.number === 6) {
      setThisTimeScore(props.answerListChart[0]); 
      setComparisonScore(props.answerListChart[1]); 
    // } else if (props.number==2){
    //   setThisTimeScore(makeScore(props.answerListChart[0]));
    } else if (props.number==2|props.number==3|props.number==4|props.number==5){
      setThisTimeScore(makeScore(props.answerListChart[0]));
      setComparisonScore(makeScore(props.answerListChart[1]));
    } else{
      setThisTimeScore(makeScore(props.answerListChart[0])); 
    }
  }, [props.answerListChart, props.number]); 
  
    
    const dataSecond = {
        labels: labels,
        datasets: [
          {
            label: 'あなたの結果',
            data: thisTimeScore,
            backgroundColor: 'rgba(35,166,186,0.2)',
            borderColor: 'rgba(35,166,186,1)',
            borderWidth: 1,
          },
          {
            label: '過去の平均',
            data: comparisonScore,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderColor: 'rgba(0, 0, 0, 0.2)',
            borderWidth: 1,
          },
        ],
      };

    const optionsSecond = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        r: {
            angleLines: {
                display: true
            },
            suggestedMin: 0,
            suggestedMax: sugestedMax
        }
    }
      }

    const dataFirst = {
        labels: ["感覚を眺める", "反応しない", "判断しない", "描写と言語化", "行動の意識化"],
        datasets: [
          {
            label: 'あなたの結果',
            data: thisTimeScore,
            backgroundColor: 'rgba(35,166,186,0.2)',
            borderColor: 'rgba(35,166,186,1)',
            borderWidth: 1,
          },
        ],
      };
    const optionsFirst = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        r: {
            angleLines: {
                display: true
            },
            suggestedMin: 0,
            suggestedMax: sugestedMax
        }
    }
  }

    const getFeedback = (index, increment) => {
      let classificationObj;
      let direction;
      if (increment > 0) {
          // If the score has increased
          classificationObj = feedbackData.upScores[index];
          direction = "高く";
      // } else if (increment < 0) {
      //     // If the score has decreased
      //     classificationObj = feedbackData.downScores[index];
      //     direction = "少なく";
      }
      
      if (classificationObj && Math.abs(increment) >= 0.6 && classificationObj.classification === classifications[index]) {
          return [classificationObj.classification,classificationObj.comment, direction];
      }
      return null;
  }
    
  const calculateChanges = (thisTimeScore, comparisonScore) => {
  const changes = [];
  for (let i = 0; i < thisTimeScore.length; i++) {
      if (comparisonScore.length > i) {
          const increment = thisTimeScore[i] - comparisonScore[i];
          const feedback = getFeedback(i, increment);
          if (feedback) {
              changes.push(feedback);
          }
      }
  }
    return changes; 
  }

  useEffect(() => {
    if (thisTimeScore.length === 5 && comparisonScore.length === 5) {
      setScoreChanges(calculateChanges(thisTimeScore, comparisonScore));
    }
  }, [thisTimeScore,comparisonScore]);
 
if (props.number === 1) {
    return (
      <Box margin="auto" marginTop="10px" marginBottom={["30px","70px"]} width={["100%", "90%"]}>
        <Radar 
        data = {dataFirst}
        options = {optionsFirst}
        />
        {/* <Text 
          marginBottom="20px"
          marginLeft="10px"
          marginRight="10px"
          fontSize="px"
        >結果の見方
        </Text> */}
        <Box 
          marginLeft="10px"
          marginRight="10px"
        >
          {explanationsArray.map((comment, index) => (
            <Text 
              key={index} 
              fontSize={["12px","18px"]} 
              marginBottom={["20px","20px"]}
            >
              {comment.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {/* {line}
                  <br /> */}
                  {lineIndex > 0 && <Box height={["5px","10px"]} />} {/* 行間を追加 */}
                  {line}
                </React.Fragment>
              ))}
            </Text>
          ))}
        </Box>
      </Box>
    )
} else if (props.number === 2) {
    return(
      <Box margin="auto" marginTop="10px" marginBottom={["30px","70px"]} width={["100%", "90%"]}>
        <Radar 
            data = {dataSecond}
            options = {optionsSecond}
        />
         <Box 
          marginLeft="10px"
          marginRight="10px"
        >
        {scoreChanges.length > 0 ? (
            scoreChanges.map((change, index) => (
                <Text 
                  key={index} 
                  fontSize={["12px","18px"]} 
                  marginBottom={["20px","20px"]}
                >
                『{change[0]}』が平均より{change[2]}なっています。
                {change[1]}
                </Text>
                ))
            ) : (
            <Text 
              fontSize={["14px","18px"]} 
              marginBottom={["0px","20px"]}
            >
              {feedbackData.NoChange.comment.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {lineIndex > 0 && <Box height={["5px","10px"]} />} {/* 行間を追加 */}
                  {line}
                </React.Fragment>
              ))}
            </Text>
            )}
          </Box>
        </Box> 
    )
  } else if (props.number === 3) {
    return(
      <Box margin="auto" marginTop="10px" marginBottom={["30px","70px"]} width={["100%", "90%"]}>
        <Text style={{marginBottom:"20px", textAlign:"center", fontFamily:'"Noto Sans JP", sans-serif'}}>
            {props.attributeData.selectedJob}
        </Text>
        <Radar 
            data = {dataSecond}
            options = {optionsSecond}
        />
         <Box 
          marginLeft="10px"
          marginRight="10px"
        >
            {scoreChanges.length > 0 ? (
                scoreChanges.map((change, index) => (
                  <Text 
                    key={index} 
                    fontSize={["12px","18px"]} 
                    marginBottom={["20px","20px"]}
                  >
                    『{change[0]}』が平均より{change[2]}なっています。
                     {change[1]}
                  </Text>
                ))
            ) : (
              <Text 
                fontSize={["12px","18px"]} 
                marginBottom={["0px","20px"]}
              >
                  {feedbackData.NoChange.comment.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>
                       {lineIndex > 0 && <Box height={["5px","10px"]} />} {/* 行間を追加 */}
                       {line}
                    </React.Fragment>
                  ))}
                </Text>
                )}
          </Box>
        </Box> 
    )
  } else if (props.number === 4) {
    return(
      <Box margin="auto" marginTop="10px" marginBottom={["30px","70px"]} width={["100%", "90%"]}>
        <Text style={{marginBottom:"20px", textAlign:"center", fontFamily:'"Noto Sans JP", sans-serif'}}>
            {props.attributeData.selectedAge}
        </Text>
        <Radar 
            data = {dataSecond}
            options = {optionsSecond}
        />
         <Box 
          marginLeft="10px"
          marginRight="10px"
        >
            {scoreChanges.length > 0 ? (
                scoreChanges.map((change, index) => (
                  <Text 
                    key={index} 
                    fontSize={["12px","18px"]} 
                    marginBottom={["20px","20px"]}
                  >
                    『{change[0]}』が平均より{change[2]}なっています。
                     {change[1]}
                  </Text>
                ))
            ) : (
              <Text 
                fontSize={["12px","18px"]} 
                marginBottom={["0px","20px"]}
              >
                  {feedbackData.NoChange.comment.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>
                      {lineIndex > 0 && <Box height={["5px","10px"]} />} {/* 行間を追加 */}
                      {line}
                    </React.Fragment>
                  ))}
                </Text>
                )}
          </Box>
        </Box> 
    )
  } else if (props.number === 5) {
    return(
      <Box margin="auto" marginTop="10px" marginBottom={["30px","70px"]} width={["100%", "90%"]}>
      <Text 
        fontSize={["15px", "18px"]}
        marginBottom="20px" 
        textAlign="center" 
        fontFamily='"Noto Sans JP", sans-serif'
      >
          {props.attributeData.selectedGender}
      </Text>
      <Radar 
          data = {dataSecond}
          options = {optionsSecond}
      />
       <Box 
        marginLeft="10px"
        marginRight="10px"
      >
          {scoreChanges.length > 0 ? (
              scoreChanges.map((change, index) => (
                <Text 
                  key={index} 
                  fontSize={["12px","18px"]} 
                  marginBottom={["20px","20px"]}
                >
                  『{change[0]}』が平均より{change[2]}なっています。
                   {change[1]}
                </Text>
              ))
          ) : (
            <Text 
              fontSize={["12px","18px"]} 
              marginBottom={["20px","20px"]}
            >
                {feedbackData.NoChange.comment.split('\n').map((line, lineIndex) => (
                  <React.Fragment key={lineIndex}>
                     {lineIndex > 0 && <Box height={["5px","10px"]} />} {/* 行間を追加 */}
                     {line}
                  </React.Fragment>
                ))}
              </Text>
              )}
        </Box>
      </Box> 
  )
} else if (props.number === 6) {
    return(
      <Box margin="auto" marginTop="10px" marginBottom={["30px","70px"]} width={["100%", "90%"]}>
        <Radar 
          data = {dataSecond}
          options = {optionsSecond}
        />
        <Box 
          marginLeft="10px"
          marginRight="10px"
        >
        <Text 
          fontSize={["12px","18px"]} 
          marginBottom={["20px","20px"]}
        >
         {feedbackData.Condition.comment.split('\n').map((line, lineIndex) => (
          <Box width={["90%", "80%"]} display="flex" justifyContent="center" alignItems="center" height="auto" margin="auto">
            <React.Fragment key={lineIndex}>
              {lineIndex > 0 && <Box height={["20px","20px"]} />} {/* 行間を追加 */}
              {line}
            </React.Fragment>
           </Box>
         ))}
       </Text>
      </Box>
    </Box> 
    )
} else if (props.number === 7) {
    return(
    <div style={{margin:"auto",marginTop:"10px",marginBottom:"70px",width:"600px"}}>
        <Radar 
            data = {dataSecond}
            options = {optionsSecond}
            />
        </div> 
    )
}
}

export default RaderChart2;