import React from 'react';
import { Box } from '@chakra-ui/react';

function CanvaDesign({ width = "100%", height="600px"}) {
    return (
        <Box
          position="relative"
          width={width}
          height={height}
          paddingBottom="0"
          boxShadow="0 2px 8px 0 rgba(63,69,81,0.16)"
          marginTop="1.6em"
          marginBottom="0.9em"
          overflow="hidden"
          borderRadius="8px"
        willChange="transform"
        >
            <iframe 
                loading="lazy"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    border: 'none',
                    padding: '0',
                    margin: '0'
                }}
                src="https://www.canva.com/design/DAF_76zKRzU/pfKRZJmjC-gyF4GT4uwEGg/watch?embed"
                allowFullScreen
                allow="fullscreen"
            ></iframe>
        </Box>
    );
}

export default CanvaDesign;


  