import { Box, Image, Text, Button, useDisclosure, Modal, ModalOverlay, ModalContent,ModalCloseButton,ModalHeader } from '@chakra-ui/react';
import RequestFormMobile from './RequestFormMobile'; 
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useContext } from 'react';
import { TrackingContext} from './App'; // この行を追加

function MobileView() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure(); 
  const imageRefs = useRef([]);
  const { addTracking } = useContext(TrackingContext); 


function handleSurveyBodyClick() {
  // ここで 'surveyBody' を直接指定
  addTracking('surveyBody', 0); // 本来は実際の滞在時間を計算して渡すべき
  navigate('/surveyBody');
}

function handleVoice1() {
  addTracking('handleVoice1', 0); // 本来は実際の滞在時間を計算して渡すべき
  navigate('/voiceMobile', { state: { scrollTo: 'voiceP1Anchor' } });
}

function handleVoice2() {
  addTracking('handleVoice2', 0); // 本来は実際の滞在時間を計算して渡すべき
  navigate('/voiceMobile', { state: { scrollTo: 'voiceP6Anchor' } });
}

function handleVoice3() {
  addTracking('handleVoice3', 0); // 本来は実際の滞在時間を計算して渡すべき
  navigate('/voiceMobile', { state: { scrollTo: 'voiceP11Anchor' } });
}

function handleClickLeft() {
  addTracking('LeftButton', 0); // トラッキングを記録
  setTimeout(() => {
    // 小さな遅延後に外部リンクへ移動
    window.location.href = 'https://lay-mindfulness.com/corporate_mindfulnes_training/';
  }, 150); // 150ミリ秒後に実行
}

function handleClickCenter() {
  addTracking('CenterButton', 0);
  onOpen()
}


function handleClickRight() {
  addTracking('RightButton', 0); // 本来は実際の滞在時間を計算して渡すべき
  setTimeout(() => {
    // 小さな遅延後に外部リンクへ移動
    window.location.href = 'https://lay-mindfulness.com/corporate_mindfulness_training_inquiry/';
  }, 150); // 150ミリ秒後に実行
}

  // 画像要素にrefを割り当てる関数
  const addToRefs = (el) => {
    if (el && !imageRefs.current.includes(el)) {
      imageRefs.current.push(el);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const now = Date.now();
  
          if (entry.isIntersecting && !entry.target.dataset.enteredAt) {
            // ビューポートに入った時刻を記録
            entry.target.dataset.enteredAt = String(now);
          } else if (!entry.isIntersecting && entry.target.dataset.enteredAt) {
            const enteredAt = parseInt(entry.target.dataset.enteredAt, 10);
            const stayedSeconds = (now - enteredAt) / 1000;
  
            // URLからファイル名を抽出（拡張子なし）
            const url = entry.target.src;
            let fileName = url.substring(url.lastIndexOf('/') + 1);
            fileName = fileName.substring(0, fileName.lastIndexOf('.'));

            // console.log(`Image: ${fileName} stayed for ${stayedSeconds} seconds`);
            addTracking(`Image: ${fileName}`, stayedSeconds); 
  
            // 次回の計算のためにenteredAtを削除
            delete entry.target.enteredAt;
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // 10%の要素が見えた時点でトリガー
      }
    );
  
    // 画像要素を監視対象に追加
    document.querySelectorAll('img').forEach(img => {
      observer.observe(img);
    });
  
    // オブザーバーのクリーンアップ
    return () => observer.disconnect();
  }, []);

    return (
      <Box>
        <Image width="100%" src="/EnjoyM1.png" alt="Landing Page 1" />
        <Box position="relative" marginBottom="0px">
            <Image width="100%" src="/EnjoyM2.png" alt="Landing Page 2" />
            <Box position="absolute" top="30%" left="0" right="0" bottom="0" display="flex" justifyContent="center">
              <iframe
                width="240"
                height="135"
                src="https://www.youtube.com/embed/0yB6-ZYRtQk?si=XKoXq2K6W5abCLIB"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </Box>
          </Box>
        <Image width="100%" src="/EnjoyM3.png" alt="Landing Page 3" />
        <Image width="100%" src="/EnjoyM4.png" alt="Landing Page 4" />
        <Image width="100%" src="/EnjoyM5.png" alt="Landing Page 5" />
        <Image width="100%" src="/EnjoyM6.png" alt="Landing Page 6" />
        <Image width="100%" src="/EnjoyM7.png" alt="Landing Page 7" />
        <Image width="100%" src="/EnjoyM8.png" alt="Landing Page 8" />
        <Box position="relative" marginBottom="0px">
          <Image width="100%" src="/EnjoyM9.png" alt="Landing Page 9" />
            <Box position="absolute" top="85%" left="50%" transform="translate(-50%, -50%)" display="flex" flexDirection="column" alignItems="center">
                <Button
                    backgroundColor="rgb(156, 193, 251)"
                    color="black"
                    _hover={{
                      backgroundColor: "rgb(136, 173, 231)"
                    }}
                    width="140px"
                    height="30px"
                    borderRadius="10px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"  // シャドーを追加
                    px={6}
                    py={3}
                    mb={4} // ボタン間のマージンを追加
                    onClick={handleSurveyBodyClick}
                >
                    <Text fontSize="13px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>早速トライしてみる</Text>
                </Button>
            </Box>
        </Box>
        <Image width="100%" src="/EnjoyM10.png" alt="Landing Page 10" />
        <Box position="relative" marginBottom="0px">
          <Image width="100%" src="/EnjoyM11.png" alt="Landing Page 11" />
            <Box position="absolute" top="80%" left="50%" transform="translate(-50%, -50%)" display="flex" flexDirection="column" alignItems="center">
                <Button
                    backgroundColor="rgb(156, 193, 251)"
                    color="black"
                    _hover={{
                      backgroundColor: "rgb(136, 173, 231)"
                    }}
                    width="140px"
                    height="30px"
                    borderRadius="10px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"  // シャドーを追加
                    px={6}
                    py={3}
                    mb={4} 
                    onClick={handleVoice1}
                    style={{ position: 'relative', zIndex: 10 }}
                >
                   <Text fontSize="13px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>体験談を読む</Text>
                </Button>
            </Box>
        </Box>
        <Box position="relative" marginBottom="0px">
          <Image width="100%" src="/EnjoyM12.png" alt="Landing Page 12" />
            <Box position="absolute" top="80%" left="50%" transform="translate(-50%, -50%)" display="flex" flexDirection="column" alignItems="center">
                <Button
                    backgroundColor="rgb(156, 193, 251)"
                    color="black"
                    _hover={{
                      backgroundColor: "rgb(136, 173, 231)"
                    }}
                    width="130px"
                    height="30px"
                    borderRadius="10px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"  // シャドーを追加
                    px={6}
                    py={3}
                    mb={4} // ボタン間のマージンを追加
                    onClick={handleVoice2}
                >
                   <Text fontSize="13px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>体験談を読む</Text>
                </Button>
            </Box>
        </Box>
        <Box position="relative" marginBottom="0px">
          <Image width="100%" src="/EnjoyM13.png" alt="Landing Page 13" />
            <Box position="absolute" top="80%" left="50%" transform="translate(-50%, -50%)" display="flex" flexDirection="column" alignItems="center">
                <Button
                    backgroundColor="rgb(156, 193, 251)"
                    color="black"
                    _hover={{
                      backgroundColor: "rgb(136, 173, 231)"
                    }}
                    width="140px"
                    height="30px"
                    borderRadius="10px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"  // シャドーを追加
                    px={6}
                    py={3}
                    mb={4} // ボタン間のマージンを追加
                    onClick={handleVoice3}
                >
                   <Text fontSize="13px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>体験談を読む</Text>
                </Button>
            </Box>
        </Box>

        <Box position="relative" marginBottom="0px" mb="10vh">
          <Image width="100%" src="/EnjoyM14.png" alt="Landing Page 14"/>
          <Box position="absolute" top="33%" left="50%" transform="translate(-50%, -50%)">
              <Button
                backgroundColor="rgb(156, 193, 251)"
                color="black"
                _hover={{
                  backgroundColor: "rgb(136, 173, 231)"
                }}
                width="220px"
                height="35px"
                borderRadius="10px"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                px={6}
                py={3}
                onClick={handleClickLeft}
              >
               <Text fontSize="12px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>ビジネスへの効用をもっと良く知る</Text>
              </Button>
            </Box>
            <Box position="absolute" top="60%" left="50%" transform="translate(-50%, -50%)">
              <Button
                backgroundColor="rgb(156, 193, 251)"
                color="black"
                fontSize="22px"
                _hover={{
                  backgroundColor: "rgb(136, 173, 231)"
                }}
                width="220px"
                height="35px"
                borderRadius="10px"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                px={6}
                py={3}
                onClick={handleClickCenter}
                // onClick={handleSurveyBodyClick}
              >
               <Text fontSize="13px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>資料をダウンロードする</Text>
              </Button>
            </Box>
            <Box position="absolute" top="83%" left="50%" transform="translate(-50%, -50%)">
              <Button
                backgroundColor="rgb(156, 193, 251)"
                color="black"
                fontSize="22px"
                _hover={{
                  backgroundColor: "rgb(136, 173, 231)"
                }}
                width="220px"
                height="35px"
                borderRadius="10px"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                px={6}
                py={3}
                onClick={handleClickRight}
                // onClick={handleSurveyBodyClick}
              >
               <Text fontSize="13px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>問い合わせてみる</Text>
              </Button>
            </Box>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
          <ModalHeader fontSize={["14px", "16px"]} marginTop="30px"textAlign="center">トライアルプログラム資料ダウンロード</ModalHeader>
            <ModalCloseButton /> {/* ここで閉じるボタンを追加 */}
            <RequestFormMobile /> {/* RequestForm コンポーネントを挿入 */}
          </ModalContent>
        </Modal>
      </Box>
    );
  }
  
export default MobileView;