import { Amplify } from 'aws-amplify';
import React, { useState, useEffect, useRef, createContext } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { ChakraProvider, Box, Text, Link, Flex } from '@chakra-ui/react';
import { UserIdProvider } from './UserIdContext';
import { createTracking, createIPAddress } from './graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import theme, { GlobalStyles } from './theme';
import RouterComponent from './RouterComponent';

Amplify.configure(awsconfig);

const client = generateClient();
export const TrackingContext = createContext(null);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const [trackingData, setTrackingData] = useState([]);
  const [ipAddress, setIpAddress] = useState('');
  const [source, setSource] = useState('');
  const ipAddressRef = useRef(''); // useRef to hold the ipAddress
  const idleTimeoutRef = useRef(null);

  useEffect(() => {
    const utmSource = new URLSearchParams(window.location.search).get('utm_source') || document.referrer;
    setSource(utmSource);
  }, []);

  function detectDeviceType() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      return 'Mobile'; // モバイルデバイス
    } else {
      return 'Desktop'; // デスクトップ
    }
  }

  const addTracking = (place, duration) => {
    const deviceType = detectDeviceType();
    setTrackingData(prevData => [...prevData, { Place: place, Duration: duration, Device: deviceType }]);
  };

  const sendTrackingData = async () => {
    const filteredTrackingData = trackingData.filter(item => item.Duration > 1);
    const trackingInfo = filteredTrackingData.length > 0 ? JSON.stringify(filteredTrackingData) : "None";
    const lastDuration = filteredTrackingData.length > 0 ? filteredTrackingData[filteredTrackingData.length - 1].Duration.toString() : "0";

    const daytime = new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
    const deviceType = detectDeviceType();
    const input = {
      Daytime: daytime,
      IPAddress: ipAddressRef.current,
      TrackingInfo: trackingInfo,
      Duration: trackingInfo === "None" ? "0" : lastDuration,
      Device: deviceType,
      Source: source
    };

    try {
      const result = await client.graphql({
        query: createTracking,
        variables: { input }
      });
      // console.log("Tracking data sent successfully", result);
      setTrackingData([]);
    } catch (error) {
      // console.error("Error sending tracking data to DynamoDB", error);
    }
  };

  const saveIpAddress = async (ip) => {
    const daytime = new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
    const input = {
      address: ip,
      daytime: daytime
    };

    try {
      const result = await client.graphql({
        query: createIPAddress,
        variables: { input }
      });
    } catch (error) {
      console.error("Error saving IP address to DynamoDB", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getAndSaveIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        if (isMounted) {
          setIpAddress(data.ip);
          ipAddressRef.current = data.ip; // Set the ipAddress in ref
          await saveIpAddress(data.ip);
        }
      } catch (error) {
        console.error("Fetching IP address failed:", error);
      }
    };

    getAndSaveIpAddress();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const handleUnload = (event) => {
      sendTrackingData();
    };

    window.addEventListener('beforeunload', handleUnload);
    window.addEventListener('pagehide', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      window.removeEventListener('pagehide', handleUnload);
    };
  }, [sendTrackingData]);

  useEffect(() => {
    const handleActivity = () => {
      clearTimeout(idleTimeoutRef.current);
      idleTimeoutRef.current = setTimeout(() => {
        sendTrackingData();
      }, 10 * 1000); // 30秒後にデータ送信
    };

    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('scroll', handleActivity);
    document.addEventListener('click', handleActivity);

    return () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('scroll', handleActivity);
      document.removeEventListener('click', handleActivity);
      clearTimeout(idleTimeoutRef.current);
    };
  }, [sendTrackingData]);

  return (
    <UserIdProvider>
      <TrackingContext.Provider value={{ addTracking, ipAddress }}>
        <ChakraProvider theme={theme}>
          <GlobalStyles />
          <BrowserRouter>
            <RouterComponent />
          </BrowserRouter>
          <Flex
            as="footer"
            height={["80px", "60px"]}
            direction={["column", "row"]}
            position="fixed"
            left={0}
            right={0}
            bottom={0}
            zIndex={1000}
            bgColor="rgba(35, 166, 186, 0.8)"
            align="center"
            justify={["center", "space-between"]}
            p={4}
          >
            <Link
              href="./"
              fontSize={["12px", "sm"]}
              ml={["0px", "20px"]}
              color="white"
            >
              はじめに戻る
            </Link>
            <Text
              marginTop={["10px", "0px"]}
              marginRight={["0px", "20px"]}
              fontSize={["12px", "15px"]}
              color="white"
            >
              Copyright ©2024 株式会社Lay. All Rights Reserved.
            </Text>
          </Flex>
        </ChakraProvider>
      </TrackingContext.Provider>
    </UserIdProvider>
  );
}

export default App;



