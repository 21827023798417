import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Flex,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { generateClient } from 'aws-amplify/api';
import { createRequestInformation } from './graphql/mutations';

const client = generateClient();


function RequestFormForSurvey({pinCode,questionState,setQuestionState}) {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();
  const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure();

  const navigate = useNavigate();

  const email = watch('email');
  const name = watch('name');
  const company = watch('company');

  // メールの正規表現パターン
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // フォームが適切に入力されたかどうかをチェック
  const isFormFilled = name && company && emailPattern.test(email);


    const onSubmit = async (data) => {
      // 分割代入を使って、フォームデータから必要な値を取得
      const { name, company, department, email } = data;
    
      // GraphQLミューテーションに渡すためのオブジェクト
      const requestInfo = {
        CompanyName: company,
        ContactName: name,
        DivisionName: department,
        Email: email,
      };

      const nextPage = (event)=>{
        setQuestionState(questionState+1);
    }
    
      try {
        // axiosとAmplifyの両方の処理をPromise.allを使って並行実行
        const [axiosResponse, amplifyResponse] = await Promise.all([
          axios.post('https://gn5qzvehll.execute-api.ap-northeast-1.amazonaws.com/staging/', {
            name: name,
            company: company,
            department: department,
            email: email ,
            request:'survey',
            pinCode:pinCode
          }),
          client.graphql({
            query: createRequestInformation,
            variables: {
              input: requestInfo
            }
          })
        ]);
    
        // レスポンスのログ
        console.log('Axios response:', axiosResponse.data);
        console.log('Amplify response:', amplifyResponse.data);
    
        // 成功時の処理
        onSuccessOpen();
        setTimeout(() => {
          onSuccessClose();
          nextPage();
        }, 2000);
      } catch (error) {
        // エラー時の処理
        console.error('Error submitting form', error);
        onErrorOpen();
      }
    };
    
    
  return (
    <Box p={5} display="flex" justifyContent="center" alignItems="center" width={["100%", "100%", "100%", "100%"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <FormControl isInvalid={errors.name}>
        <Flex display="flex" alignItems="center">
            <Box minHeight="30px" marginLeft={["-10px","0px"]}>
                <Box bg="#23A6BA" width={["25px","30px"]} height="20px" color="white" mr="5px" borderRadius="8px" 
                    display="flex" justifyContent="center" alignItems="center">
                    <Text fontSize={["8px","10px"]}>必須</Text>
                </Box>
            </Box>
            <Box marginRight={["12px","23px"]}  minHeight="30px">
                <FormLabel htmlFor="name" fontSize={["12px", "15px"]}>氏名</FormLabel>
            </Box>
            <Box  minHeight="30px" marginBottom="5px">
                 <Input 
                    id="name" 
                    width={["195px","250px"]} 
                    placeholder="氏名の入力"
                    sx={{
                      '::placeholder': {  // CSSの擬似要素を使用
                        fontSize: ['12px', '14px', '16px']  // レスポンシブフォントサイズ
                      }
                    }}
                    {...register('name', { required: '氏名は必須です' })}
                  />
            </Box>
            <FormErrorMessage>
                {errors.name && errors.name.message}
            </FormErrorMessage>
        </Flex>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.company}>
        <Flex display="flex" alignItems="center">
              <Box minHeight="30px" marginLeft={["-10px","0px"]}>
                  <Box bg="#23A6BA" width={["25px","30px"]} height="20px" color="white" mr="5px" borderRadius="8px" 
                      display="flex" justifyContent="center" alignItems="center">
                      <Text fontSize={["8px","10px"]}>必須</Text>
                  </Box>
              </Box>
            <Box marginRight={["0px","8px"]}  minHeight="30px">
              <FormLabel htmlFor="company" fontSize={["12px", "15px"]}>会社名</FormLabel>
            </Box>
            <Box minHeight="30px" marginBottom="5px">
                 <Input 
                    id="name" 
                    width={["195px","250px"]} 
                    placeholder="会社名の入力"
                    sx={{
                      '::placeholder': {  // CSSの擬似要素を使用
                        fontSize: ['12px', '14px', '16px']  // レスポンシブフォントサイズ
                      }
                    }}
                    {...register('company', { required: '会社名は必須です' })}
                  />
            </Box>
            <FormErrorMessage>
                {errors.company && errors.company.message}
            </FormErrorMessage>
            </Flex>
        </FormControl>

        <FormControl mt={4}>
        <Flex display="flex" alignItems="center">
            <Box bg="white" width="30px" height="20px" color="white" mr="0px" borderRadius="8px" 
                display="flex" justifyContent="center" alignItems="center">
            </Box>
            <Box marginRight={["0px","10px"]} marginLeft={["-10px","0px"]}>
                 <FormLabel htmlFor="department" fontSize={["12px", "16px"]}>部署名</FormLabel>
            </Box>
            <Box marginBottom="5px">
                {/* <Input id="department" width="250px" placeholder="部署名" {...register('department')} /> */}
                <Input 
                    id="name" 
                    width={["195px","250px"]} 
                    placeholder="部署名の入力"
                    sx={{
                      '::placeholder': {  // CSSの擬似要素を使用
                        fontSize: ['12px', '14px', '16px']  // レスポンシブフォントサイズ
                      }
                    }}
                    {...register('department')}
                  />
            </Box>
            </Flex>
        </FormControl>


        <FormControl mt={4} isInvalid={errors.email}>
        <Flex display="flex" alignItems="center">
             <Box minHeight="30px" marginLeft={["-10px","0px"]}>
                <Box bg="#23A6BA" width={["25px","30px"]} height="20px" color="white" mr="5px" borderRadius="8px" 
                    display="flex" justifyContent="center" alignItems="center">
                    <Text fontSize="10px">必須</Text>
                </Box>
             </Box>
             <Box marginRight={["0px","6px"]} minHeight="30px">
                <FormLabel htmlFor="email" fontSize={["12px", "16px"]}>メール</FormLabel>
             </Box>
             <Box minHeight="30px" marginBottom="5px">
                {/* <Input id="email" width="250px" placeholder="メール" {...register('email', { required: 'メールアドレスは必須です', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "無効なメールアドレスです" } })} /> */}
                <Input 
                    id="name" 
                    width={["195px","250px"]} 
                    placeholder="メールの入力"
                    sx={{
                      '::placeholder': {  // CSSの擬似要素を使用
                        fontSize: ['12px', '14px', '16px']  // レスポンシブフォントサイズ
                      }
                    }}
                    {...register('email', { required: 'emailは必須です' })}
                  />
            </Box>
             <FormErrorMessage>
                    {errors.email && errors.email.message}
             </FormErrorMessage>
            </Flex>
        </FormControl>
        </Stack>
        <Flex display="flex" justifyContent="center" alignItems="center">
        <Button
            type="submit" 
            height={["30px", "40px"]}
            fontSize={["12px","16px"]}
            paddingTop="10px"
            paddingRight="15px"
            paddingBottom="10px"
            paddingLeft="15px"
            color="white"
            bg={isFormFilled ? "#23A6BA" : "#D3D3D3"} // ここで背景色を変更
            _hover={{ bg: isFormFilled ? "#2BB1C5" : "#D3D3D3" }} // ホバー状態の背景色も変更
            _active={{ bg: isFormFilled ? "#1E8A9D" : "#D3D3D3" }} // アクティブ状態の背景色も変更
            marginTop={"50px"}
            marginBottom={"20px"}
            borderRadius="15"
            border="none"
            disabled={!isFormFilled} 
            >４桁のコードを送信
        </Button>
        </Flex>
      </form>
      <Modal isOpen={isSuccessOpen} onClose={onSuccessClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader fontSize={["12px", "18px"]}>送信が成功しました</ModalHeader>
              <ModalBody fontSize={["12px", "18px"]}>
                メールをご確認ください。1-2分時間がかかるときがございます。
              </ModalBody>
          <ModalFooter>
            <Button bg="#2eb1c6" color="white" mr={3} onClick={onSuccessClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* 送信エラー時のモーダル */}
      <Modal isOpen={isErrorOpen} onClose={onErrorClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={["12px", "18px"]}>送信に失敗しました</ModalHeader>
          <ModalBody fontSize={["12px", "18px"]}>サーバーエラーが発生しました。後ほど再試行してください。</ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onErrorClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default RequestFormForSurvey;