// import './App.css';
import React,{ useState,useContext} from "react";
import questionsData from "./Questions.json";
import Header from "./Header";
import {Amplify} from "aws-amplify";
import { generateClient } from 'aws-amplify/api';
import {createLsmData,createAttributeData,createConditionData} from "./graphql/mutations";
import aws_exports from "./aws-exports";
import OptionToggle from "./OptionToggle";
import { useNavigate } from 'react-router-dom';
import { ChakraProvider,Flex, Image, Button,Box, 
  useDisclosure,FormControl, FormLabel, Input, 
  Text,VStack,Stack,Alert, AlertIcon, useEditable,
  Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,ModalCloseButton
} from "@chakra-ui/react";
import { useUserId } from './UserIdContext'; 
import CanvaDesign from './CanvaDesign';
import RaderChart2 from './Chart2';
import RequestFormForSurvey from "./RequestFormForSurvey";
import PinVerification from "./PinVerfication";
import { TrackingContext } from './App';

Amplify.configure(aws_exports)
const client = generateClient();

function SurveyBody({}) {

  const answers = [
    {id:"1", label:"1.いつも当てはまる"},
    {id:"2", label: "2.しばしば当てはまる"}, 
    {id:"3", label: "3.たまに当てはまる"}, 
    {id:"4", label: "4.ほとんど当てはまらない"},
    {id:"5", label: "5.全く当てはまらない"}
];
  const questions = questionsData;
  const [transition, setTransition] = useState(true);
  const contextValue = useUserId();
  const userId = contextValue.userId;
  const setUserId = contextValue.setUserId;
  const [answerList, setAnswerList] = useState([])
  const [chartDisplay, setChartDisplay] = useState(false);
  const [questionState, setQuestionState] = useState(-1)
  // const [lastAnswerList, setLastAnswerList] = useState([])
  const [selectedOption, setSelectedOption] = useState(answers[0].id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [canClick, setCanClick] = useState(true);
  const { ipAddress } = useContext(TrackingContext);

  const navigate = useNavigate();

  function generateUserId() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため、1を足す
    const dd = String(today.getDate()).padStart(2, '0');
    const randomNum = Math.floor(Math.random() * 10000); // 4桁のランダムな数値を生成
  
    return `${yyyy}${mm}${dd}-${randomNum}`;
  }

  function generatePinCode() {
    const generatePinCode =  Math.floor(1000 + Math.random() * 9000).toString();
    setPinCode(generatePinCode)
}

  React.useEffect(() => {
    if (questionState === -1) {
      window.scrollTo(0, 0);
      const userIdGenerated = generateUserId();
      setUserId(userIdGenerated)
      setAnswerList([])
    } else if (questionState === 16){
        onOpen();
        generatePinCode();
    }
  }, [questionState]); 


  const handleOptionSelect = ((option) => {
    if (!canClick) return; 
    setCanClick(false); 
    setAnswerList([...answerList, Number(option.id)]);
    setSelectedOption(option);
    // setVal(option.label)
    // answerToScore(option.label)
    setTimeout(()=>{
    setTransition(false);
      },1000);
    setTimeout(()=>{
        setTransition(true);
        setQuestionState(questionState+1);
        // setVal("")
        setSelectedOption(answers[0].id)
        setCanClick(true);  
      },1500);
    });

  const nextPage = (event)=>{
      const newQuestionState = questionState + 1;
      setQuestionState(newQuestionState);
      // if (newQuestionState==16){
      //   fixResult()
      // }
  }

  const fixResult = async () =>{
    console.log("通過")
    setTimeout(()=>{
      setTransition(false);
        },1000);
    try{
      await client.graphql({
          query:createLsmData, 
          variables:
            {input:{
              personId:ipAddress, 
              lsmData:answerList 
              }
          }
      });
        console.log("LSM送信成功")
        setChartDisplay(true);// GraphQLクエリ成功後に実行
    } catch (error) {
      console.log("✅エラー", error);
    }
  }

  const nextStep = () => {
    navigate('/surveyOneMore');
    setAnswerList([])
  };

  const handleModalClose = () => {
    onClose();
    nextPage();  // モーダルを閉じた時にフォームが送信されたとマーク
  };
 
    if (questionState===-1){
      return(
      <div className='App'>
        <Header/>
          <Text fontSize={["20px","25px"]} marginBottom="50px" marginTop="100px" paddingLeft="20px" paddingRight="20px">マインドフルネスの程度測定を始めます</Text>
          <Text fontSize={["12px","18px"]} marginTop={["5vh", "8vh"]} paddingLeft="20px" paddingRight="20px">質問数は全部で15問です。</Text>
          <Text fontSize={["12px","18px"]} marginTop={["2vh", "5vh"]} paddingLeft="20px" paddingRight="20px">リラックスして直感的にお答えください。</Text>
          <Text fontSize={["12px","18px"]} marginTop={["2vh", "5vh"]}  paddingLeft="20px" paddingRight="20px">回答を選択すると1秒後に自動的にページが推移します。</Text>
          <Text fontSize={["12px","18px"]} marginTop={["2vh", "5vh"]}  marginBottom="50px" paddingLeft="20px" paddingRight="20px">これはテストではなく、何が良い、悪いはありませんので、気軽にトライしてください！</Text>
        <Button
          onClick={nextPage}
          size={["sm","lg"]}
          fontSize="20px"
          paddingTop="10px"
          paddingRight="15px"
          paddingBottom="10px"
          paddingLeft="15px"
          color="white"
          bg="#23A6BA"
          _hover={{ bg: "#2BB1C5" }}
          _active={{ bg: "#1E8A9D" }}
          mt="5px"
          mb="100px"
          borderRadius="10px"
          border="none"
          >開始
        </Button>
      </div>
    )
    } else if (questionState===15 & chartDisplay===false){
      return(
      <div className="App">
        <Header/>
        <Text 
          fontSize={["16px","25px"]} 
          marginTop="180px" 
          marginBottom="50px"
        >
          終了しました！お疲れ様でした
        </Text>
        <Button
          onClick={fixResult}
          size={["sm","lg"]}
          fontSize="20px"
          paddingTop="10px"
          paddingRight="15px"
          paddingBottom="10px"
          paddingLeft="15px"
          color="white"
          bg="#23A6BA"
          _hover={{ bg: "#2BB1C5" }}
          _active={{ bg: "#1E8A9D" }}
          marginTop={"30px"}
          marginBottom={"30px"}
          borderRadius="10px"
          border="none"
          >結果を見る
        </Button>
      </div>
    ) } else if (questionState===15 & chartDisplay===true) {
    return(
    <>
         <Header/>
         <Box textAlign="center" marginBottom="50px" marginTop="100px" >
              <Text  fontSize={["15px","18px"]} marginLeft="0px" fontFamily='"Noto Sans JP", sans-serif'>あなたのマインドフルネスの程度</Text>
          </Box>
          <Box width={["90%", "80%"]} display="flex" justifyContent="center" alignItems="center" height="auto" margin="auto">
            <RaderChart2
              answerListChart={[answerList]}
              number = {1}
            />
          </Box>
          <Flex justifyContent="center" alignItems="center" direction="flex">
           <Button
            onClick={nextPage}
            size={["sm","lg"]}
            fontSize="20px"
            paddingTop="10px"
            paddingRight="15px"
            paddingBottom="10px"
            paddingLeft="15px"
            color="white"
            bg="#23A6BA"
            _hover={{ bg: "#2BB1C5" }}
            _active={{ bg: "#1E8A9D" }}
            marginTop={"10px"}
            marginBottom={"100px"}
            borderRadius="10px"
            border="none"
          >次へ
        </Button>
        </Flex>
    </>
      )}
      else if (questionState===16 ){
        return(
          <Box position="relative" width="100%" height="100vh">  
          {/* <img width="100%" src="/further_survey_guide.png" alt="Landing Page 1" />
          <img width="100%" src="/mobil_further_survey_guide.png" alt="Landing Page 1" /> */}
          <Image
            src="/further_survey_guide.png"
            alt="Landing Page 1"
            display={['none', 'none', 'block']}  // ベース, スモール, ミディアム以上で表示
            width="100%"
            height="100%"
            objectFit="cover" // 画像がコンテナサイズにフィットするよう調整
            position="absolute"
            top="0"
            left="0"
          />
          {/* モバイル用の画像 */}
          <Image
            src="/mobile_further_survey_guide.png"
            alt="Landing Page 1"
            display={['block', 'block', 'none']}  // ベース, スモールで表示, ミディアム以上で非表示
            width="100%"
          />
          <Button
            onClick={nextPage}
            size={["sm","lg"]}
            fontSize="16px"
            paddingTop="10px"
            paddingRight="15px"
            paddingBottom="10px"
            paddingLeft="15px"
            color="white"
            bg="#23A6BA"
            _hover={{ bg: "#2BB1C5" }}
            _active={{ bg: "#1E8A9D" }}
            position="absolute"
            top="68%"
            left="50%"
            transform="translate(-50%, -50%)" // 中心からのオフセットを調整
            borderRadius="10px"
            border="none"
          >
            4桁の数字を受信する
          </Button>
          </Box>
        )
      }
    else if (questionState===17 ){
      return(
      <Modal isOpen={isOpen} onClose={nextPage}>
        <ModalOverlay />
        <ModalContent size="xl" bg="white">
          <ModalHeader fontSize="16px">平均データとの比較結果をリクエスト</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="14px" marginBottom="20px">４桁のコードをメールで送信します。送信ボタンを押すとコード入力画面に切り替わります。</Text>
            <RequestFormForSurvey 
              pinCode={pinCode}
              questionState={questionState} 
              setQuestionState={setQuestionState}
              onSubmitted={handleModalClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
      )
    }
    else if (questionState===18 ){
      return(
      <PinVerification pinCode={pinCode}/>
      )
    }
    else if (transition===true){
        return (
          <div>
      <ChakraProvider>
      <Header/>
        <VStack spacing={20} alignItems="center" marginBottom="100px" marginTop="100px">
          <Stack>
            <Text
                textAlign= "left"
                lineHeight= "2"
                fontSize= {["14px","20px"]}
                paddingLeft= {["30px","100px"]}
                paddingRight= {["30px","100px"]}
                maxWidth= "100%"
                wordWrap= "break-word"
                marginTop="50px"
                marginBottom="20px"
            >
              {questions[questionState].question}
            </Text>
          </Stack>
          <Box>
            <Flex alignItems="center" justifyContent="center" h="25vh" marginTop="10px" marginBottom="100px">
              <Flex flexDirection="Column">
                {answers.map((option) => (
                  <OptionToggle
                    key={option.id}
                    option={option}
                    isSelected={option.id === selectedOption.id}
                    onClick={() => handleOptionSelect(option)}
                  />
                ))}
              </Flex>
            </Flex>

            <Box marginBottom="100px"></Box> 
          </Box>
        </VStack>
      </ChakraProvider>
    </div>
  )
  }
}

export default SurveyBody;