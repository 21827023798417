import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Flex,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { generateClient } from 'aws-amplify/api';
import { createRequestInformation } from './graphql/mutations';

const client = generateClient();

function RequestFormMobile() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();
  const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure();
  const navigate = useNavigate();


    const onSubmit = async (data) => {
      // 分割代入を使って、フォームデータから必要な値を取得
      const { name, company, department, email } = data;
    
      // GraphQLミューテーションに渡すためのオブジェクト
      const requestInfo = {
        CompanyName: company,
        ContactName: name,
        DivisionName: department,
        Email: email,
      };
    
      try {
        // axiosとAmplifyの両方の処理をPromise.allを使って並行実行
        const [axiosResponse, amplifyResponse] = await Promise.all([
          axios.post('https://gn5qzvehll.execute-api.ap-northeast-1.amazonaws.com/staging/', {
            name: name,
            company: company,
            department: department,
            email: email ,
            request:"download"
          }),
          client.graphql({
            query: createRequestInformation,
            variables: {
              input: requestInfo
            }
          })
        ]);
    
        // レスポンスのログ
        console.log('Axios response:', axiosResponse.data);
        console.log('Amplify response:', amplifyResponse.data);
    
        // 成功時の処理
        onSuccessOpen();
        setTimeout(() => {
          onSuccessClose();
          navigate('/');
        }, 2000);
      } catch (error) {
        // エラー時の処理
        console.error('Error submitting form', error);
        onErrorOpen();
      }
    };

  return (
    <Box p={5} marginTop="10px" display="flex" justifyContent="center" alignItems="center">
      <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
      <FormControl isInvalid={errors.name}>
            <Flex direction="column" alignItems="start">
            <Flex alignItems="center" ml="20px">
            <FormLabel fontSize="15px" display="flex" alignItems="center" height="25px">氏名</FormLabel>
                <Box bg="#A9A9A9" width="30px" height="25px" color="white" mr="5px" borderRadius="5px" 
                    display="flex" justifyContent="center" alignItems="center" mb="8px">
                    <Text fontSize="10px" lineHeight="25px" fontWeight="bold">必須</Text> {/* TextのlineHeightをBoxのheightに合わせる */}
                </Box>
            </Flex>
                <Box minHeight="30px" marginBottom="5px" ml="20px">
                    <Input id="name" width="240px" placeholder="氏名の入力" {...register('name', { required: '氏名は必須です' })} />
                    <FormErrorMessage>
                        {errors.name && errors.name.message}
                    </FormErrorMessage>
                </Box>
            </Flex>
        </FormControl>

         <FormControl mt={4} isInvalid={errors.company}>
            <Flex direction="column" alignItems="start">
                <Flex alignItems="center" ml="20px">
                <FormLabel fontSize="15px" display="flex" alignItems="center" height="25px">会社名</FormLabel> 
                    <Box bg="#A9A9A9" width="30px" height="25px" color="white" mr="5px" borderRadius="5px" 
                        display="flex" justifyContent="center" alignItems="center" mb="8px">
                        <Text fontSize="10px" lineHeight="25px" fontWeight="bold">必須</Text>
                    </Box>
                </Flex>
                    <Box minHeight="30px" marginBottom="5px" ml="20px">
                        <Input id="name" width="240px" placeholder="会社名" {...register('company', { required: '会社名は必須です' })} />
                        <FormErrorMessage>
                            {errors.company && errors.company.message}
                        </FormErrorMessage>
                    </Box>
              </Flex>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.company}>
            <Flex direction="column" alignItems="start">
                <Flex alignItems="center" ml="20px">
                    <FormLabel fontSize="15px" display="flex" alignItems="center" height="25px">部署名</FormLabel> {/* FormLabelもFlexとして扱う */}
                </Flex>
                    <Box minHeight="30px" marginBottom="5px" ml="20px">
                        <Input id="name" width="240px" placeholder="部署名" {...register('department')} />
                        <FormErrorMessage>
                            {errors.company && errors.company.message}
                        </FormErrorMessage>
                    </Box>
              </Flex>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.company}>
            <Flex direction="column" alignItems="start">
                <Flex alignItems="center" ml="20px">
                <FormLabel fontSize="15px" display="flex" alignItems="center" height="25px">メール</FormLabel> 
                    <Box bg="#A9A9A9" width="30px" height="25px" color="white" mr="5px" borderRadius="5px" 
                        display="flex" justifyContent="center" alignItems="center" mb="8px">
                        <Text fontSize="10px" lineHeight="25px" fontWeight="bold">必須</Text>
                    </Box>
                </Flex>
                    <Box minHeight="30px" marginBottom="5px" ml="20px">
                        <Input id="name" width="240px" placeholder="メール"{...register('email', { required: 'メールアドレスは必須です', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "無効なメールアドレスです" } })} />
                        <FormErrorMessage>
                            {errors.company && errors.company.message}
                        </FormErrorMessage>
                    </Box>
              </Flex>
        </FormControl>


        </Stack>
        <Flex display="flex" justifyContent="center" alignItems="center">
        <Button
            type="submit" 
            height="30px"
            fontSize="14px"
            paddingTop="10px"
            paddingRight="15px"
            paddingBottom="10px"
            paddingLeft="15px"
            color="white"
            bg="#23A6BA"
            _hover={{ bg: "#2BB1C5" }}
            _active={{ bg: "#1E8A9D" }}
            marginTop={"50px"}
            marginBottom={"50px"}
            borderRadius="6"
            border="none"
            >リクエストを送信
        </Button>
        </Flex>
      </form>
      <Modal isOpen={isSuccessOpen} onClose={onSuccessClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={["12px", "18px"]}>送信が成功しました</ModalHeader>
          <ModalBody fontSize={["12px", "18px"]}>メールをご確認ください。1-2分時間がかかるときがございます。</ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onSuccessClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* 送信エラー時のモーダル */}
      <Modal isOpen={isErrorOpen} onClose={onErrorClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={["12px", "18px"]}>送信に失敗しました</ModalHeader>
          <ModalBody fontSize={["12px", "18px"]}>サーバーエラーが発生しました。後ほど再試行してください。</ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onErrorClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default RequestFormMobile;