import { Box, Text } from "@chakra-ui/react";

// カスタムトーストコンポーネント
function CustomToast({ title, description, status }) {
  let bgColor;
  switch (status) {
    case 'success':
      bgColor = '#2eb1c6';
      break;
    case 'error':
      bgColor = 'red.500';
      break;
  }

  return (
    <Box color="white" p={3} bg={bgColor} textAlign="center">
      <Text fontSize="sm">{title}</Text>
      <Text fontSize="sm">{description}</Text>
    </Box>
  );
}

export default CustomToast;
