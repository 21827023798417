import { Box, Text,Button, Image, useDisclosure, Modal, ModalOverlay, ModalContent,ModalCloseButton,ModalHeader } from '@chakra-ui/react';
import React, { useEffect, useRef, useContext } from 'react';
import RequestFormMobile from './RequestFormMobile'; 
import { useNavigate,useLocation } from 'react-router-dom';
import { TrackingContext} from './App'; // この行を追加

function VoiceMobile() {
  const imageRefs = useRef([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure(); 
  const { addTracking } = useContext(TrackingContext); 
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state?.scrollTo) {
      setTimeout(() => {
        const element = document.getElementById(location.state.scrollTo);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500); // 500ミリ秒後に実行
    }
  }, [location.state]);
  
  

function handleSurveyBodyClick() {
  // ここで 'surveyBody' を直接指定
  addTracking('surveyBody', 0); // 本来は実際の滞在時間を計算して渡すべき
  navigate('/surveyBody');
}

function handleClickLeft() {
  addTracking('LeftButton', 0); // トラッキングを記録
  setTimeout(() => {
    // 小さな遅延後に外部リンクへ移動
    window.location.href = 'https://lay-mindfulness.com/corporate_mindfulnes_training/';
  }, 150); // 150ミリ秒後に実行
}

function handleClickCenter() {
  addTracking('CenterButton', 0);
  onOpen()
}


function handleClickRight() {
  addTracking('RightButton', 0);
  setTimeout(() => {
    // 小さな遅延後に外部リンクへ移動
    window.location.href = 'https://lay-mindfulness.com/corporate_mindfulness_training_inquiry/';
  }, 150); // 150ミリ秒後に実行
}

  // 画像要素にrefを割り当てる関数
  const addToRefs = (el) => {
    if (el && !imageRefs.current.includes(el)) {
      imageRefs.current.push(el);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const now = Date.now();
  
          if (entry.isIntersecting && !entry.target.dataset.enteredAt) {
            // ビューポートに入った時刻を記録
            entry.target.dataset.enteredAt = String(now);
          } else if (!entry.isIntersecting && entry.target.dataset.enteredAt) {
            const enteredAt = parseInt(entry.target.dataset.enteredAt, 10);
            const stayedSeconds = (now - enteredAt) / 1000;
  
            // URLからファイル名を抽出（拡張子なし）
            const url = entry.target.src;
            let fileName = url.substring(url.lastIndexOf('/') + 1);
            fileName = fileName.substring(0, fileName.lastIndexOf('.'));

            // console.log(`Image: ${fileName} stayed for ${stayedSeconds} seconds`);
            addTracking(`Image: ${fileName}`, stayedSeconds); 
  
            // 次回の計算のためにenteredAtを削除
            delete entry.target.enteredAt;
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // 10%の要素が見えた時点でトリガー
      }
    );
  
    // 画像要素を監視対象に追加
    document.querySelectorAll('img').forEach(img => {
      observer.observe(img);
    });
  
    // オブザーバーのクリーンアップ
    return () => observer.disconnect();
  }, []);
  
  
    return (
      <Box>
          <div id="voiceP1Anchor"></div> 
          <Image width="100%" src="/VoiceM1.png" alt="Landing Page 3" />
          <Image width="100%" src="/VoiceM2.png" alt="Landing Page 3" />
          <Image width="100%" src="/VoiceM3.png" alt="Landing Page 3" />
          <Image width="100%" src="/VoiceM4.png" alt="Landing Page 4" />
          <Image width="100%" src="/VoiceM5.png" alt="Landing Page 5" />
          <div id="voiceP6Anchor"></div> 
          <Image width="100%" src="/VoiceM6.png" alt="Landing Page 6" />
          <Image width="100%" src="/VoiceM7.png" alt="Landing Page 7" />
          <Image width="100%" src="/VoiceM8.png" alt="Landing Page 8" />
          <Image width="100%" src="/VoiceM9.png" alt="Landing Page 9" />
          <Image width="100%" src="/VoiceM10.png" alt="Landing Page 10" />
          <div id="voiceP11Anchor"></div> 
          <Image width="100%" src="/VoiceM11.png" alt="Landing Page 11" />
          <Image width="100%" src="/VoiceM12.png" alt="Landing Page 12" />
          <Image width="100%" src="/VoiceM13.png" alt="Landing Page 13" />
          <Image width="100%" src="/VoiceM14.png" alt="Landing Page 14" />
          
          <Box position="relative" marginBottom="0px">
            <Image width="100%" src="/VoiceM15.png" alt="Landing Page 15" />
            <Box position="absolute" top="85%" left="50%" transform="translate(-50%, -50%)">
              <Button
                backgroundColor="rgb(156, 193, 251)"
                color="black"
                fontSize="22px"
                _hover={{
                  backgroundColor: "rgb(136, 173, 231)"
                }}
                width="140px"
                height="30px"
                borderRadius="10px"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                px={6}
                py={3}
                onClick={handleSurveyBodyClick}
              >
               <Text fontSize="13px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>早速トライしてみる</Text>
              </Button>
            </Box>
          </Box>
        
        <Box position="relative" marginBottom="0px" mb="10vh">
          <Image width="100%" src="/VoiceM16.png" alt="Landing Page 16" />
          <Box position="absolute" top="38%" left="50%" transform="translate(-50%, -50%)">
              <Button
                backgroundColor="rgb(156, 193, 251)"
                color="black"
                fontSize="22px"
                _hover={{
                  backgroundColor: "rgb(136, 173, 231)"
                }}
                width="220px"
                height="35px"
                borderRadius="10px"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                px={6}
                py={3}
                onClick={handleClickLeft}
                // onClick={handleSurveyBodyClick}
              >
               <Text fontSize="12px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>ビジネスへの効用をもっと良く知る</Text>
              </Button>
            </Box>
            <Box position="absolute" top="66%" left="50%" transform="translate(-50%, -50%)">
              <Button
                backgroundColor="rgb(156, 193, 251)"
                color="black"
                fontSize="22px"
                _hover={{
                  backgroundColor: "rgb(136, 173, 231)"
                }}
                width="220px"
                height="35px"
                borderRadius="10px"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                px={6}
                py={3}
                onClick={handleClickCenter}
                // onClick={handleSurveyBodyClick}
              >
               <Text fontSize="12px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>資料をダウンロードする</Text>
              </Button>
            </Box>
            <Box position="absolute" top="90%" left="50%" transform="translate(-50%, -50%)">
              <Button
                backgroundColor="rgb(156, 193, 251)"
                color="black"
                fontSize="12px"
                _hover={{
                  backgroundColor: "rgb(136, 173, 231)"
                }}
                width="220px"
                height="35px"
                borderRadius="10px"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                px={6}
                py={3}
                onClick={handleClickRight}
                // onClick={handleSurveyBodyClick}
              >
               <Text fontSize="12px" fontFamily="Makinas-4-Flat" style={{ fontWeight: 100 }}>問い合わせてみる</Text>
              </Button>
            </Box>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
          <ModalHeader fontSize={["14px", "16px"]} marginTop="30px"textAlign="center">トライアルプログラム資料ダウンロード</ModalHeader>
            <ModalCloseButton /> {/* ここで閉じるボタンを追加 */}
            <RequestFormMobile /> {/* RequestForm コンポーネントを挿入 */}
          </ModalContent>
        </Modal>
      </Box>
    );
  }
  
export default VoiceMobile;