// theme.js
import { extendTheme } from '@chakra-ui/react';
import { Global, css } from '@emotion/react'

// グローバルスタイルを適用するためのコンポーネント
export const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Makinas-4-Flat';
        src: url('/fonts/Makinas-4-Flat.woff') format('woff');
      }
    `}
  />
);

// カスタムテーマを拡張
const theme = extendTheme({
    fonts: {
      heading: 'Makinas-4-Flat',
      body: 'Makinas-4-Flat',
    },
  });
  
export default theme;
