// Layout.js
import React from "react";
import { DrawerHeader, Flex, Image, Text } from "@chakra-ui/react";

const Header = ({ }) => {
  return (
    <>
      <Flex height="80px" as="header" position="fixed" top={0} left={0} right={0} zIndex={1000} bgColor="rgba(255, 255, 255, 0.8)" align="center" justify="space-between" p={4}>
        <Image src={`${process.env.PUBLIC_URL}/LayLogo.png`} alt="Logo" width={["100px","200px"]} height={["22.5px","45px"]} />
        <Text fontSize={["12px","20px"]} marginRight="20px" fontFamily="Courgette, cursive" color="#23A6BA">
          Lay Mindfulness Survey
        </Text>
      </Flex>
    </>
  );
};

export default Header;
