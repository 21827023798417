import { Box, Flex, Text,VStack, useToken,Button } from "@chakra-ui/react";
import {useState, useEffect} from "react";


const Conditions= (props) => {

    const [selectedSholderCondition, setSelectedSholderCondition] = useState("");
    const [selectedSleepCondition, setSelectedSleepCondition] = useState("");
    const [selectedMentalCondition, setSelectedMentalCondition] = useState("");
    const [selectedFocusCondition, setSelectedFocusCondition] = useState("");
    const [selectedRelationship, setSelectedRelationship] = useState("");

    const handleSholderConditionClick = (value) => {
      setSelectedSholderCondition(value);
    
      props.setConditionData((prev) => {
        const updatedData = {...prev, sholderCondition:value};
        return updatedData;
      });
    
      if (value && selectedSleepCondition && selectedMentalCondition && selectedFocusCondition && selectedRelationship) {
        setTimeout(() => {
          props.setQuestionState(props.questionState + 1);
        }, 1000);
      }
    };
    
    const handleSleepConditionClick = (value) => {
      setSelectedSleepCondition(value);
    
      props.setConditionData((prev) => {
        const updatedData = {...prev, sleepCondition:value};
        return updatedData;
      });
    
      if (selectedSholderCondition && value && selectedMentalCondition && selectedFocusCondition && selectedRelationship) {
        setTimeout(() => {
          props.setQuestionState(props.questionState + 1);
        }, 1000);
      }
    };
    
    const handleMentalConditionClick = (value) => {
      setSelectedMentalCondition(value);
    
      props.setConditionData((prev) => {
        const updatedData = {...prev, mentalCondition:value};
        return updatedData;
      });
    
      if (selectedSholderCondition && selectedSleepCondition && value && selectedFocusCondition && selectedRelationship) {
        setTimeout(() => {
          props.setQuestionState(props.questionState + 1);
        }, 1000);
      }
    };
    
    const handleFocusConditionClick = (value) => {
      setSelectedFocusCondition(value);
    
      props.setConditionData((prev) => {
        const updatedData = {...prev, focusCondition:value};
        return updatedData;
      });
    
      if (selectedSholderCondition && selectedSleepCondition && selectedMentalCondition && value && selectedRelationship) {
        setTimeout(() => {
          props.setQuestionState(props.questionState + 1);
        }, 1000);
      }
    };
    
    const handleRelationshipClick = (value) => {
      setSelectedRelationship(value);
    
      props.setConditionData((prev) => {
        const updatedData = {...prev, relationship:value};
        return updatedData;
      });
    
      if (selectedSholderCondition && selectedSleepCondition && selectedMentalCondition && selectedFocusCondition && value) {
        setTimeout(() => {
          props.setQuestionState(props.questionState + 1);
        }, 1000);
      }
    };
    

      useEffect(() => {
          window.scrollTo(0, 0);
      }, []); 

      const sholderPainOptions = [
        { value: "ひどく痛む", label: "ひどく痛む", onClick: handleSholderConditionClick },
        { value: "少し痛む", label: "少し痛む", onClick: handleSholderConditionClick },
        { value: "ほとんど感じない", label: "ほとんど感じない", onClick: handleSholderConditionClick },
      ];
      
      const sleepConditionOptions = [
        { value: "あまり眠れない", label: "あまり眠れない", onClick: handleSleepConditionClick },
        { value: "夜中に時々起きてしまう", label: "夜中に時々起きてしまう", onClick: handleSleepConditionClick },
        { value: "よく眠れている", label: "よく眠れている", onClick: handleSleepConditionClick },
      ];
      
      const mentalConditionOptions = [
        { value: "すぐに怒ったりイライラする", label: "すぐに怒ったりイライラする", onClick: handleMentalConditionClick },
        { value: "怒りやイライラが起こるが表には出さない", label: "怒りやイライラが起こるが表には出さない", onClick: handleMentalConditionClick },
        { value: "ほどんと怒りやイライラを感じない", label: "ほとんど怒りやイライラを感じない", onClick: handleMentalConditionClick },
      ];

      const focusConditionOptions = [
        { value: "集中しようとしても出来ないことが多い", label: "集中しようとしても出来ないことが多い", onClick: handleFocusConditionClick },
        { value: "集中することは出来るが長続きしない", label: "集中することは出来るが長続きしない", onClick: handleFocusConditionClick },
        { value: "集中したい時は、集中することが出来る", label: "集中したい時は、集中することが出来る", onClick: handleFocusConditionClick },
      ];

      const relationshipOptions = [
        { value: "親しい人でも会うのが億劫に感じる", label: "親しい人でも会うのが億劫に感じる", onClick: handleRelationshipClick },
        { value: "親しくない人に会うのは憂鬱に感じる", label: "親しくない人に会うのは憂鬱に感じる", onClick: handleRelationshipClick },
        { value: "人と会うことを苦痛に感じない", label: "人と会うことを苦痛に感じない", onClick: handleRelationshipClick },
      ];

      const CustomOption = ({ option, isSelected}) => {
        const [bgColorSelected, bgColorNotSelected] = useToken("colors",["#23A6BA","#c0c0c0"]);

        return (
          <Flex
            display="block"
            width={["90%","420px"]}
            onClick={() => option.onClick(option.value)}
            mb={["0px","10px"]}
            cursor="pointer"
            alignItems="center"
            borderRadius="lg"
            p={["15px", "15px"]}
          >
            <Flex alignItems="center">
            <Box
                bg={isSelected ? bgColorSelected : bgColorNotSelected}
                borderRadius="50%"
                width="15px"
                height={["15px","15px"]}
                mr={2}
                position="relative"
            >
            {isSelected && (
              <Box
                bg={bgColorSelected}
                borderRadius="50%"
                width="12px"
                height="12px"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              />
            )}
            </Box>
              {/* <Box whiteSpace="nowrap" marginLeft="10px"> */}
              <Box whiteSpace="normal" marginLeft="5px" width="calc(100% - 20px)">
                <Text fontSize={["12px","18px"]} fontFamily="Arial, sans-serif" textAlign="left">
                  {option.label}
                </Text>
              </Box>
            </Flex>
          </Flex>
        );
      };

      //１回目のコンディションのデータを取得する
      let sholderCondition;
      let sleepCondition;
      let mentalCondition;
      let focusCondition;
      let relationship;
      if (props.firstSecondTime === "1回目") {
      } else if (props.firstSecondTime === "2回目以降") {
        if (typeof props.previousConditionData === 'string') {
          if (props.previousConditionData.length > 50) {
            let previousConditionDataString = props.previousConditionData;
            let colonDataString = previousConditionDataString.replace(/=/g, ':');
            let dataArray = colonDataString.slice(1, -1).split(", ");
            let tempObject = {};
            dataArray.forEach((item) => {
                let keyValue = item.split(":");
                tempObject[keyValue[0].trim()] = keyValue[1].trim();
            });
            sholderCondition = tempObject["sholderCondition"];
            sleepCondition = tempObject["sleepCondition"];
            mentalCondition = tempObject["mentalCondition"];
            focusCondition = tempObject["focusCondition"]
            relationship = tempObject["relationship"];
          } else {
            sholderCondition = "何らかの原因で前回の記載がありません";
            sleepCondition = "何らかの原因で前回の記載がありません";
            mentalCondition = "何らかの原因で前回の記載がありません";
            focusCondition = "何らかの原因で前回の記載がありません";
            relationship = "何らかの原因で前回の記載がありません";
          }
        } else {
            sholderCondition = "何らかの原因で前回の記載がありません";
            sleepCondition = "何らかの原因で前回の記載がありません";
            mentalCondition = "何らかの原因で前回の記載がありません";
            focusCondition = "何らかの原因で前回の記載がありません";
            relationship = "何らかの原因で前回の記載がありません";
        }
      }

      return (
        <div className="App content">
          <Text fontSize={["15px","20px"]} marginTop={["100px", "150px"]}>1. 肩こり・偏頭痛</Text>
          <VStack 
            justifyContent={"center"} 
            flexWrap={"wrap"} 
            marginTop={["15px","30px"]} 
            paddingLeft="20px"
          >
          {sholderPainOptions.map((option) => (
            <CustomOption
              key={option.value}
              option={option}
              isSelected={selectedSholderCondition === option.value}
              onClick={handleSholderConditionClick}
            />
          ))}
          </VStack>
          <Text fontSize={["15px","20px"]} marginTop={["50px", "80px"]}>2.睡眠の状態</Text>
          <VStack 
            justifyContent={"center"} 
            flexWrap={"wrap"} 
            marginTop={["15px","30px"]} 
            paddingLeft="20px"
          >
            {sleepConditionOptions.map((option) => (
                <CustomOption
                key={option.value}
                option={option}
                isSelected={selectedSleepCondition === option.value}
                onClick={handleSleepConditionClick}
                />
            ))}
            </VStack>
            <Text fontSize={["15px","20px"]} marginTop={["50px", "80px"]}>3.こころの状態</Text>
            <VStack 
              justifyContent={"center"} 
              flexWrap={"wrap"} 
              marginTop={["15px","30px"]} 
              paddingLeft="20px"
            >
            {mentalConditionOptions.map((option) => (
                <CustomOption
                key={option.value}
                option={option}
                isSelected={selectedMentalCondition === option.value}
                onClick={handleMentalConditionClick}
                />
            ))}
            </VStack>
            <Text fontSize={["15px","20px"]} marginTop={["50px", "80px"]}>4.集中力の状態</Text>
            <VStack 
              justifyContent={"center"} 
              flexWrap={"wrap"} 
              marginTop={["15px","30px"]} 
              paddingLeft="20px"
            >
            {focusConditionOptions.map((option) => (
                <CustomOption
                key={option.value}
                option={option}
                isSelected={selectedFocusCondition === option.value}
                onClick={handleFocusConditionClick}
                />
            ))}
            </VStack>
            <Text fontSize={["15px","20px"]} marginTop={["50px", "80px"]}>5.人間関係の状態</Text>
            <VStack 
              justifyContent={"center"} 
              flexWrap={"wrap"} 
              marginTop={["15px","30px"]} 
              paddingLeft="20px"
            >
            {relationshipOptions.map((option) => (
                <CustomOption
                key={option.value}
                option={option}
                isSelected={selectedRelationship === option.value}
                onClick={handleRelationshipClick}
                />
            ))}
            </VStack>
        </div>
        );
    }
export default Conditions;