import { Box, Text,Flex } from '@chakra-ui/react';
import Header from "./Header";

function ResultRequest() {
    return (
      <Box>
        <Header/>
        <Flex height="100vh" justifyContent="center" alignItems="center">
            <Box>
                <Text fontSize="100px">作成中</Text>
            </Box>
        </Flex>
      </Box>
    );
  }
  
export default ResultRequest;