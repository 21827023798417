import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Flex,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { generateClient } from 'aws-amplify/api';
import { createRequestInformation } from './graphql/mutations';

const client = generateClient();


function RequestForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();
  const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure();
  const navigate = useNavigate();


    const onSubmit = async (data) => {
      // 分割代入を使って、フォームデータから必要な値を取得
      const { name, company, department, email } = data;
    
      // GraphQLミューテーションに渡すためのオブジェクト
      const requestInfo = {
        CompanyName: company,
        ContactName: name,
        DivisionName: department,
        Email: email,
      };
    
      try {
        // axiosとAmplifyの両方の処理をPromise.allを使って並行実行
        const [axiosResponse, amplifyResponse] = await Promise.all([
          axios.post('https://gn5qzvehll.execute-api.ap-northeast-1.amazonaws.com/staging/', {
            name: name,
            company: company,
            department: department,
            email: email,
            request:"download"
          }),
          client.graphql({
            query: createRequestInformation,
            variables: {
              input: requestInfo
            }
          })
        ]);
    
        // レスポンスのログ
        console.log('Axios response:', axiosResponse.data);
        console.log('Amplify response:', amplifyResponse.data);
    
        // 成功時の処理
        onSuccessOpen();
        setTimeout(() => {
          onSuccessClose();
          navigate('/');
        }, 2000);
      } catch (error) {
        // エラー時の処理
        console.error('Error submitting form', error);
        onErrorOpen();
      }
    };
    
    
  return (
    <Box p={5} marginTop="20px" display="flex" justifyContent="center" alignItems="center">
      <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl isInvalid={errors.name}>
        <Flex display="flex" alignItems="center">
            <Box minHeight="30px">
                <Box bg="#A9A9A9" width="30px" height="20px" color="white" mr="5px" borderRadius="4px" 
                    display="flex" justifyContent="center" alignItems="center">
                    <Text fontSize="10px" fontWeight="bold">必須</Text>
                </Box>
            </Box>
            <Box marginRight="23px"  minHeight="30px">
                <FormLabel htmlFor="name">氏名:</FormLabel>
            </Box>
            <Box  minHeight="30px" marginBottom="5px">
                 <Input id="name" width="280px" placeholder="氏名の入力" {...register('name', { required: '氏名は必須です' })} />
            </Box>
            <FormErrorMessage>
                {errors.name && errors.name.message}
            </FormErrorMessage>
        </Flex>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.company}>
        <Flex display="flex" alignItems="center">
            <Box minHeight="30px">
                <Box bg="#A9A9A9" width="30px" height="20px" color="white" mr="5px" borderRadius="4px" 
                    display="flex" justifyContent="center" alignItems="center">
                    <Text fontSize="10px"  fontWeight="bold">必須</Text>
                </Box>
            </Box>
            <Box minHeight="30px" marginRight="9px">
                <FormLabel htmlFor="company">会社名:</FormLabel>
            </Box>
            <Box minHeight="30px" marginBottom="5px">
                <Input id="company" width="280px" placeholder="会社名" {...register('company', { required: '会社名は必須です' })} />
            </Box>
            <FormErrorMessage>
                {errors.company && errors.company.message}
            </FormErrorMessage>
            </Flex>
        </FormControl>

        <FormControl mt={4}>
        <Flex display="flex" alignItems="center">
            <Box bg="white" width="30px" height="20px" color="white" mr="5px" borderRadius="4px" 
                display="flex" justifyContent="center" alignItems="center">
            </Box>
            <Box marginRight="13px">
                 <FormLabel htmlFor="department">部署名</FormLabel>
            </Box>
            <Box marginBottom="5px">
                <Input id="department" width="280px" placeholder="部署名" {...register('department')} />
            </Box>
            </Flex>
        </FormControl>


        <FormControl mt={4} isInvalid={errors.email}>
        <Flex display="flex" alignItems="center">
             <Box minHeight="30px">
                <Box bg="#A9A9A9" width="30px" height="20px" color="white" mr="5px" borderRadius="4px" 
                    display="flex" justifyContent="center" alignItems="center">
                    <Text fontSize="10px"  fontWeight="bold">必須</Text>
                </Box>
             </Box>
             <Box marginRight="13px" minHeight="30px">
                <FormLabel htmlFor="email">メール</FormLabel>
             </Box>
             <Box minHeight="30px" marginBottom="5px">
                <Input id="email" width="280px" placeholder="メール" {...register('email', { required: 'メールアドレスは必須です', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "無効なメールアドレスです" } })} />
             </Box>
             <FormErrorMessage>
                    {errors.email && errors.email.message}
             </FormErrorMessage>
            </Flex>
        </FormControl>
        </Stack>
        <Flex display="flex" justifyContent="center" alignItems="center">
        <Button
            type="submit" 
            height="30px"
            fontSize="15px"
            paddingTop="10px"
            paddingRight="15px"
            paddingBottom="10px"
            paddingLeft="15px"
            color="white"
            bg="#23A6BA"
            _hover={{ bg: "#2BB1C5" }}
            _active={{ bg: "#1E8A9D" }}
            marginTop={"50px"}
            marginBottom={"50px"}
            borderRadius="5"
            border="none"
            >リクエストを送信
        </Button>
        </Flex>
      </form>
      <Modal isOpen={isSuccessOpen} onClose={onSuccessClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>送信が成功しました</ModalHeader>
          <ModalBody>メールをご確認ください。1-2分時間がかかるときがございます。</ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onSuccessClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* 送信エラー時のモーダル */}
      <Modal isOpen={isErrorOpen} onClose={onErrorClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>送信に失敗しました</ModalHeader>
          <ModalBody>サーバーエラーが発生しました。後ほど再試行してください。</ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onErrorClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default RequestForm;


