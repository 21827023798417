import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Attribute from './Attribute';
import Conditions from './Conditions';
import Header from './Header';
import { Box,Flex, Button,Text,Link } from "@chakra-ui/react";
import {Amplify} from "aws-amplify";
import aws_exports from "./aws-exports";
import { generateClient } from 'aws-amplify/api';
import RaderChart2 from './Chart2';
import { sholderPainOptions, sleepConditionOptions, mentalConditionOptions, focusConditionOptions, relationshipOptions } from './ConditonOptions';
import { useUserId } from './UserIdContext'; 
import { listLsmData } from './graphql/queries';

Amplify.configure(aws_exports)
const client = generateClient();

const NextButton = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            size={["sm","lg"]}
            fontSize="20px"
            pt="10px"
            pr="15px"
            pb="10px"
            pl="15px"
            color="white"
            bg="#23A6BA"
            _hover={{ bg: "#2BB1C5" }}
            _active={{ bg: "#1E8A9D" }}
            mt="10px"
            mb="100px"
            borderRadius="10px"
            border="none"
        >
            次へ
        </Button>
    );
};

const SurveyOneMore = () => {
    const contextValue = useUserId();
    const userId  ="20240411-1104"
    // const userId = contextValue.userId;
    const navigate = useNavigate();
    const [answerList, setAnswerList] = useState([]);
    const [questionState, setQuestionState] = useState(0);
    const [conditionData, setConditionData] = useState([]);
    const [attributeData, setAttributeData] = useState({ selectedJob: '', selectedAge: '', selectedGender: '' });
    const [chartDisplay, setChartDisplay] = useState(false);
    const [conditionScores, setConditionScores] = useState({});
    const [totalAverageScore, setTotalAverageScore] = useState([]);
    const [occupationAverageScore,setOccupationAverageScore] = useState([])
    const [ageAverageScore,setAgeAverageScore] = useState([])
    const [genderAverageScore,setGenderAverageScore] = useState([])
    
    const averageScore = [2.51,2.74,2.67,2.76,2.63,2.81,2.98,2.64,2.86,3.34,2.68,2.82,2.66,2.96,3.17] //データ数 610
    const averageScoreSales = [2.6, 2.8, 2.8, 2.6, 2.8, 3.0, 2.9, 2.7, 2.9, 3.4, 2.7, 2.6, 2.7, 2.8, 3.2] //82
    const averageScoreEngineer =   [2.5, 2.8, 2.7, 2.9, 2.5, 2.7, 3.1, 2.7, 2.8, 3.3, 2.8, 2.9, 2.6, 3.0, 3.1] //159
    const averageScoreAdmin =   [2.5, 2.9, 2.7, 2.8, 2.6, 2.9, 3.1, 2.6, 2.9, 3.3, 2.7, 2.9, 2.7, 3.0, 3.2] //188
    const averageScore20 =  [2.5, 2.6, 2.4, 2.7, 2.2, 2.4, 3.0, 2.5, 2.7, 3.0, 2.4, 2.7, 2.2, 2.7, 2.7] //65
    const averageScore30 =  [2.7, 3.0, 2.8, 2.6, 2.5, 2.8, 3.1, 2.9, 3.0, 3.3, 2.7, 2.9, 2.6, 3.0, 3.1] //47
    const averageScore40 =   [2.6, 3.0, 2.7, 2.9, 2.6, 3.0, 3.2, 2.7, 2.7, 3.3, 2.9, 2.9, 2.7, 3.1, 3.2] //115
    const averageScore50 =  [2.4, 2.8, 2.7, 2.8, 2.7, 2.9, 3.0, 2.6, 2.9, 3.4, 2.8, 2.8, 2.8, 3.0, 3.2] //163
    const averageScore60 =   [2.5, 2.7, 3.1, 2.7, 2.8, 3.0, 3.0, 2.9, 3.3, 3.5, 2.7, 2.8, 2.9, 3.0, 3.4]//39
    const averageScoreMale =  [2.6, 2.9, 2.8, 2.7, 2.7, 2.9, 3.0, 2.7, 2.9, 3.4, 2.7, 2.8, 2.7, 3.0, 3.2] //282
    const averageScoreFemale =  [2.4, 2.8, 2.5, 3.0, 2.4, 2.7, 3.1, 2.7, 2.8, 3.1, 2.8, 3.0, 2.7, 3.0, 3.0] //146
    const averageScoreOther =  [1.0, 2.0, 5.0, 1.0, 4.0, 4.0, 1.0, 2.0, 5.0, 5.0, 1.0, 1.0, 1.0, 1.0, 5.0] //1
    const averageScoreCondition = [2.14,2.21,1.98,2.17,2.09]

    useEffect(() => {
        const getSurveyData = async () => {
            try {
                const response = await client.graphql({
                    query: listLsmData,
                    variables: {
                        limit: 50, // 必要に応じてlimitを設定
                        nextToken: null // paginationが必要な場合はこの値を設定
                    }
                });
                console.log("全データ取得成功", response);

                // レスポンスからitemsを取得し、userIdでフィルタリング
                const filteredData = response.data.listLsmData.items.filter(item => item.personId === userId);
                console.log("フィルタリングされたデータ", filteredData[0].lsmData);
                const answerListChart = JSON.parse(filteredData[0].lsmData);
                setAnswerList(answerListChart);  // 変換した配列を状態にセッ

                if (filteredData.length > 0) {
                    setChartDisplay(true); // データが存在すれば表示を更新
                }
            } catch (error) {
                console.log("GraphQLクエリエラー", error);
            }
        };

        getSurveyData();
    }, []);

    console.log("lsm data", answerList)

    useEffect(() => {
        if (questionState === -1) {
            window.scrollTo(0, 0);
        }

    }, [questionState]);

    const nextPage = () => {
        setQuestionState(questionState + 1);
        window.scrollTo(0, 0); 
    };

    const getScore = (condition, options) => {
        const index = options.findIndex(option => option.value === condition);
        return index !== -1 ? index + 1 : null;
    };

    const convertConditionsToScores = (conditionData, options) => {
        return [
          getScore(conditionData.focusCondition, options.focusConditionOptions),
          getScore(conditionData.mentalCondition, options.mentalConditionOptions),
          getScore(conditionData.relationship, options.relationshipOptions),
          getScore(conditionData.sholderCondition, options.sholderPainOptions),
          getScore(conditionData.sleepCondition, options.sleepConditionOptions),
        ];
      };
      
    
    const options = {
        sholderPainOptions,
        sleepConditionOptions,
        mentalConditionOptions,
        focusConditionOptions,
        relationshipOptions,
    };

    useEffect(() => {
        if (questionState === 2) {
          const newScores = convertConditionsToScores(conditionData, {
            sholderPainOptions,
            sleepConditionOptions,
            mentalConditionOptions,
            focusConditionOptions,
            relationshipOptions
          });
          setConditionScores(newScores);
        }
      }, [questionState, conditionData]);

      useEffect(() => {
        if (questionState === 2) {
            // ここにスコア選択ロジックを配置
            let chosenAverageScore;
            if (attributeData.selectedJob === '営業・接客') {
                setOccupationAverageScore(averageScoreSales);
            } else if (attributeData.selectedJob === "技術・エンジニア") {
                setOccupationAverageScore(averageScoreEngineer);
            } else if (attributeData.selectedJob === "専門職、管理") {
                setOccupationAverageScore(averageScoreAdmin);
            } if (attributeData.selectedAge === "20代以下") {
                setAgeAverageScore(averageScore20)
            } else if (attributeData.selectedAge === "30代") {
                setAgeAverageScore(averageScore30)
            } else if (attributeData.selectedAge === '40代') {
                setAgeAverageScore(averageScore40)
            } else if (attributeData.selectedAge === '50代') {
                setAgeAverageScore(averageScore50)
            } else if (attributeData.selectedAge === '60代以上') {
                setAgeAverageScore(averageScore60)
            } if (attributeData.selectedGender === '女性') {
                setGenderAverageScore(averageScoreFemale)
            } else if (attributeData.selectedGender === '男性') {
                setGenderAverageScore(averageScoreMale)
            } else if (attributeData.selectedGender === 'その他・回答しない') {
                setGenderAverageScore(averageScoreOther)
            } 
        }
        setTotalAverageScore(averageScore) 
    }, [questionState, attributeData]); 

    useEffect(() => {
        if (questionState === 2) {
          window.scrollTo(0, 0);
        }
      }, [questionState]);

    if (questionState === 0) {
        return (
            <>
                <Header />
                <Attribute
                    personId={userId}
                    questionState={questionState}
                    setQuestionState={setQuestionState}
                    attributeData={attributeData}
                    setAttributeData={setAttributeData}
                />
            </>
        );
    } else if (questionState === 1) {
        return (
            <>
                <Header />
                <Box marginBottom="100px">
                    <Conditions
                        personId={userId}
                        conditionData={conditionData}
                        setConditionData={setConditionData}
                        questionState={questionState}
                        setQuestionState={setQuestionState}
                        marginBottom="200px"
                    />
                </Box>
            </>
        );
    } else if (questionState===2){
    return(
    <>
        <Box textAlign="center" marginBottom="50px" marginTop="100px" >
            <Text fontFamily='"Noto Sans JP", sans-serif'>あなたのマインドフルネス度--受講者平均と比較</Text>
        </Box>
         <Header/>
         <Box width={["90%", "80%"]} display="flex" justifyContent="center" alignItems="center" height="auto" margin="auto">
         <RaderChart2
            answerListChart={[answerList,totalAverageScore]}
            number = {2}
            attributeData = {attributeData}
          />
        </Box>
        <Flex justifyContent="center" alignItems="center" maxWidth="100%">
           <NextButton onClick={nextPage}/>
        </Flex>
    </>
    )}
    else if (questionState===3){
        return(
        <Box>
          <Header/>
            <Box textAlign="center" marginBottom="50px" marginTop="100px" >
                <Text marginLeft="20px" fontFamily='"Noto Sans JP", sans-serif'>職種別--受講者平均と比較</Text>
            </Box>
            <Box width={["90%", "80%"]} display="flex" justifyContent="center" alignItems="center" height="auto" margin="auto">
              <RaderChart2
               answerListChart={[answerList,occupationAverageScore]}
                number = {3}
                attributeData = {attributeData}
              />
            </Box>
            <Flex justifyContent="center" alignItems="center">
               <NextButton onClick={nextPage}/>
            </Flex>
        </Box>
    )}
    else if (questionState===4){
        return(
        <>
                <Box textAlign="center" marginBottom="50px" marginTop="100px" >
                    <Text  marginLeft="20px" fontFamily='"Noto Sans JP", sans-serif'>年齢層別--受講者平均と比較</Text>
                </Box>
                <Header/>
                <Box width={["90%", "80%"]} display="flex" justifyContent="center" alignItems="center" height="auto" margin="auto">
                    <RaderChart2
                        answerListChart={[answerList,ageAverageScore]}
                        number = {4}
                        attributeData = {attributeData}
                    />
                </Box>
            <Flex justifyContent="center" alignItems="center">
                <NextButton onClick={nextPage}/>
            </Flex>
        </>
        )}
        else if (questionState===5){
            return(
            <>
                    <Box textAlign="center" marginBottom="50px" marginTop="100px" >
                        <Text  marginLeft="20px" fontFamily='"Noto Sans JP", sans-serif'>性別--受講者平均と比較</Text>
                    </Box>
                    <Header/>
                    <Box width={["90%", "80%"]} display="flex" justifyContent="center" alignItems="center" height="auto" margin="auto">
                        <RaderChart2
                            answerListChart={[answerList,genderAverageScore]}
                            number = {5}
                            attributeData = {attributeData}
                        />
                    </Box>
                <Flex justifyContent="center" alignItems="center">
                    <NextButton onClick={nextPage}/>
                </Flex>
            </>
            )}
    else if (questionState===6){
            return(
            <>
            <Box textAlign="center" marginBottom="50px" marginTop="100px" >
                <Text  marginLeft="20px" fontFamily='"Noto Sans JP", sans-serif'>あなたの状態を受講者平均と比較</Text>
            </Box>
            <Header/>
                <RaderChart2
                answerListChart={[conditionScores,averageScoreCondition]}
                number = {6}
                attributeData = {attributeData}
                />
            <Flex justifyContent="center" alignItems="center">
                <NextButton onClick={nextPage}/>
            </Flex>
            </>
            )}
    else if (questionState === 7) {
        return (
            <Box textAlign="center" p={5} marginTop="15vh" marginBottom={["100px","100px"]}>
            <Header/>
                <Text fontSize="xl" fontFamily='"Noto Sans JP", sans-serif'>
                    サーベイへのご参加、誠にありがとうございました。
                </Text>
                {/* <Text
                    marginTop={["5vh","10vh"]}
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["12px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    いかがでしたか？
                </Text> */}
                <Text
                    marginTop="3vh"
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["12px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    ご自身のマインドフルネスの状態や日常生活の様々な側面について、気づきはありましたか？
                </Text>
                {/* <Text
                    marginTop="0vh"
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["12px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    少しでも気づきを得ていただくことできたならうれしいです。
                </Text> */}
                <Text
                    marginTop="3vh"
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["12px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    もし今回のサーベイでマインドフルネスにさらにご興味を持っていただけたなら、ぜひ弊社のホームページもご覧ください。
                </Text>
                {/* <Text
                    marginTop="0vh"
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["12px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    
                </Text> */}
               
                <Text
                    marginTop="3vh"
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["12px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    また、ご質問やさらなる情報をご希望の場合は、些細なことでもどうぞお気軽にお問い合わせください。
                </Text>
                <Text
                    marginTop="3vh"
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["12px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    今後も、あなたの健やかな生活と心の平穏のために、マインドフルネスで様々なサポートをご提供できればと思います。
                </Text>
                <Box marginTop={["5vh","8vh"]}>
                <Link 
                    href="https://www.lay-mindfulness.com" 
                    isExternal color="teal.500"
                    fontSize={["14px","20px"]}
                >
                   www.lay-mindfulness.com
                </Link>
                </Box>
              
                {/* <Text
                    marginTop="0vh"
                    width={["90%","80%"]}
                    maxWidth="960px" // 最大幅を指定
                    textAlign="center" // テキストを左寄せにする
                    marginLeft="auto" // 左側のマージンを自動で調整
                    marginRight="auto" // 右側のマージンを自動で調整
                    fontSize={["14px","16px"]}
                    lineHeight="2"
                    fontFamily='"Noto Sans JP", sans-serif'
                >
                    マインドフルネスで様々なサポートをご提供できればと思います。
                </Text> */}
            </Box>
        );
    }
            
};

export default SurveyOneMore;
