import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { TrackingContext } from '../App';

function usePageTime() {
  const location = useLocation();
  const [startTime, setStartTime] = useState(Date.now());
  const { addTracking } = useContext(TrackingContext);

  useEffect(() => {
    setStartTime(Date.now());

    const handleUnload = () => {
      const endTime = Date.now();
      const duration = (endTime - startTime) / 1000;
      if (duration > 1) { 
        addTracking(location.pathname, duration);
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []); // 初回ロード時のみ実行

  useEffect(() => {
    const handleNavigation = () => {
      const endTime = Date.now();
      const duration = (endTime - startTime) / 1000; // 秒単位での滞在時間
      if (duration > 1) { // 1秒以上の場合にのみトラッキングデータを記録
        addTracking(location.pathname, duration);
      }
      setStartTime(Date.now());
    };

    handleNavigation(); // location が変わったときに handleNavigation を呼び出す

    return () => {
      // クリーンアップ関数内では handleNavigation を呼び出さない
    };
  }, [location]); 

  return { startTime, location };
}

export default usePageTime;
