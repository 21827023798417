/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIPAddress = /* GraphQL */ `
  query GetIPAddress($id: ID!) {
    getIPAddress(id: $id) {
      id
      address
      daytime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIPAddresses = /* GraphQL */ `
  query ListIPAddresses(
    $filter: ModelIPAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIPAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        daytime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTracking = /* GraphQL */ `
  query GetTracking($id: ID!) {
    getTracking(id: $id) {
      id
      Daytime
      IPAddress
      TrackingInfo
      Duration
      Device
      Source
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTrackings = /* GraphQL */ `
  query ListTrackings(
    $filter: ModelTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrackings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Daytime
        IPAddress
        TrackingInfo
        Duration
        Device
        Source
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequestInformation = /* GraphQL */ `
  query GetRequestInformation($id: ID!) {
    getRequestInformation(id: $id) {
      id
      CompanyName
      ContactName
      DivisionName
      Email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequestInformations = /* GraphQL */ `
  query ListRequestInformations(
    $filter: ModelRequestInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CompanyName
        ContactName
        DivisionName
        Email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConditionData = /* GraphQL */ `
  query GetConditionData($id: ID!) {
    getConditionData(id: $id) {
      id
      personId
      conditionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConditionData = /* GraphQL */ `
  query ListConditionData(
    $filter: ModelConditionDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditionData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personId
        conditionData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLsmData = /* GraphQL */ `
  query GetLsmData($id: ID!) {
    getLsmData(id: $id) {
      id
      personId
      lsmData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLsmData = /* GraphQL */ `
  query ListLsmData(
    $filter: ModelLsmDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLsmData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personId
        lsmData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAttributeData = /* GraphQL */ `
  query GetAttributeData($id: ID!) {
    getAttributeData(id: $id) {
      id
      personId
      attributeData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAttributeData = /* GraphQL */ `
  query ListAttributeData(
    $filter: ModelAttributeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttributeData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personId
        attributeData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeditationResultData = /* GraphQL */ `
  query GetMeditationResultData($id: ID!) {
    getMeditationResultData(id: $id) {
      id
      personId
      meditationResultData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeditationResultData = /* GraphQL */ `
  query ListMeditationResultData(
    $filter: ModelMeditationResultDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeditationResultData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        meditationResultData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
