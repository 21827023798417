import React, { useEffect, useState } from 'react';
import { listTrackings } from './graphql/queries'; // あなたのGraphQLクエリ
import aws_exports from "./aws-exports";
import { Amplify } from "aws-amplify";
import { generateClient } from 'aws-amplify/api';
import { Box, Spinner, Grid, Button, Input, Flex, Text } from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';
import { format, isValid } from 'date-fns';

Amplify.configure(aws_exports);
const client = generateClient();

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend
);

const excludedIPs = ['164.70.196.53','133.200.180.0','101.111.97.104'];
const ipInfoApiKey = '20c918e1f278408b8ffedcf9ac14475d';

const httpGetAsync = (url, callback) => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function() {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
      callback(JSON.parse(xmlHttp.responseText));
    }
  };
  xmlHttp.open("GET", url, true); // true for asynchronous
  xmlHttp.send(null);
};

const fetchIpInfo = (ip) => {
  return new Promise((resolve) => {
    const url = `https://ipgeolocation.abstractapi.com/v1/?api_key=${ipInfoApiKey}&ip_address=${ip}`;
    httpGetAsync(url, (response) => {
      resolve(response);
    });
  });
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const TrackingDataComponent = () => {
  const [trackings, setTrackings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dataFetched, setDataFetched] = useState(false);

  const handleFetchData = async () => {
    setLoading(true);
    setDataFetched(false);
    try {
      const response = await client.graphql({
        query: listTrackings,
        variables: {
          limit: 50,
          nextToken: null,
        },
      });

      const filteredTrackings = response.data.listTrackings.items
        .map(item => ({
          ...item,
          tokyoTime: new Date(new Date(item.Daytime).getTime() + 9 * 60 * 60 * 1000) // 東京時間に変換
        }))
        .filter(item => {
          const itemDate = item.tokyoTime.getTime();
          const start = new Date(startDate).getTime();
          const end = new Date(endDate).getTime();
          return itemDate >= start && itemDate <= end && !excludedIPs.includes(item.IPAddress) && item.Duration > 0 && item.TrackingInfo !== "None";
        });

      const trackingsWithIpInfo = [];
      for (const tracking of filteredTrackings) {
        const ipInfo = await fetchIpInfo(tracking.IPAddress);
        trackingsWithIpInfo.push({
          ...tracking,
          city: ipInfo.city,
          country: ipInfo.country,
          organization_name: ipInfo.connection.organization_name,
        });
        await delay(1000); // 1秒の遅延を追加
      }

      setTrackings(trackingsWithIpInfo);
      setLoading(false);
      setDataFetched(true);
    } catch (error) {
      console.error('Error fetching trackings', error);
      setLoading(false);
      setDataFetched(true);
    }
  };

  const processData = (trackingInfo) => {
    let labels = [];
    let data = [];

    if (trackingInfo && trackingInfo !== 'None') {
      const parsedData = JSON.parse(trackingInfo);
      let previousDuration = 0;

      parsedData.forEach(item => {
        const place = item.Place.replace("Image: ", "").replace("Enjoy", "");
        const duration = item.Duration - previousDuration;
        previousDuration = item.Duration;

        if (duration > 0) { // 0より大きい場合のみ追加
          labels.push(place);
          data.push(duration);
        }
      });
    }

    // X軸の固定
    const allPlacesM = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12', 'M13', 'M14'];
    const allPlacesP = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12'];

    const allPlaces = labels.some(label => label.startsWith('M')) ? allPlacesM : allPlacesP;

    const placeDurationMap = Object.fromEntries(labels.map((label, index) => [label, data[index]]));
    const fixedData = allPlaces.map(place => placeDurationMap[place] || 0);

    return {
      labels: allPlaces,
      datasets: [
        {
          label: 'Duration',
          data: fixedData,
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  return (
    <Box marginTop="30px" marginBottom="80px" marginX="10%">
      <Flex mb={4} marginX="10%" width="80%" alignItems="center">
        <Input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Start Date"
          mr={2}
          width="250px"
        />
        <Input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="End Date"
          width="250px"
          mr={2}
        />
        <Button
          onClick={handleFetchData}
          colorScheme="teal"
          width="200px"
        >
          データ取得
        </Button>
      </Flex>
      {loading ? (
        <Spinner />
      ) : (
        dataFetched && (
          trackings.length === 0 ? (
            <Text marginX="30%" marginTop="5%">この期間のデータはありません</Text>
          ) : (
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              {trackings.map((tracking, index) => {
                const chartData = processData(tracking.TrackingInfo);
                const formattedDate = isValid(tracking.tokyoTime) ? format(tracking.tokyoTime, 'yyyy/MM/dd HH:mm:ss') : 'Invalid date';
                return (
                  <Box key={index}>
                    <Line data={chartData} options={{
                      plugins: {
                        title: {
                          display: true,
                          text: `City: ${tracking.city}, Country: ${tracking.country}, Org: ${tracking.organization_name}\n${formattedDate}`
                        }
                      }
                    }} />
                  </Box>
                );
              })}
            </Grid>
          )
        )
      )}
    </Box>
  );
};

export default TrackingDataComponent;


