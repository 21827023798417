import React from 'react';
import { Box, Text, Button,Image,Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import CanvaDesign from './CanvaDesign';
import { useNavigate } from 'react-router-dom';

function TopPage() {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();


  const nextPage = () => {
    navigate('/surveyBody');
  };

  return (
    <Box
      style={{
        backgroundImage: 'url("/LMS_Opening.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative',
      }}
    >
       <Image
        src="/LMS_Opening_Youtube.png" // 画像のパス
        alt="Open Modal"
        position="absolute"
        top="60%" // 位置を調整
        left="50%"
        transform="translate(-50%, -50%)" // 画像を中心に配置
        cursor="pointer" // カーソルをポインターに変更
        onClick={onOpen} // 画像をクリックしたときにModalを開く
        width="500px" // 幅を200pxに設定
        height="300px" // 高さを100pxに設定
        objectFit="cover" // 画像がコンテナを覆うように調整
      />
      <Button
        position="absolute"
        top="83vh"
        left="42%"
        alignItems="center"
        bgGradient="linear(to-r, rgb(10, 178, 196), rgb(138, 83, 254))"
        color="white"
        _hover={{
          bgGradient: "linear(to-r, rgb(138, 83, 254), rgb(10, 178, 196))"
        }}
        width="220px"
        borderRadius="10px"
        px={6}
        py={3}
        onClick={nextPage}
      >
        サーベイを開始する
      </Button>

      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lay Mindfulness Survey 1分間説明動画</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify="center">
              <CanvaDesign width="77%"/>
            </Flex>
            <Button
              position="absolute"
              marginTop="40px"
              left="42%"
              alignItems="center"
              bgGradient="linear(to-r, rgb(10, 178, 196), rgb(138, 83, 254))"
              color="white"
              _hover={{
                bgGradient: "linear(to-r, rgb(138, 83, 254), rgb(10, 178, 196))"
              }}
              width="220px"
              borderRadius="10px"
              px={6}
              py={3}
              onClick={nextPage}
            >
              <Text fontSize="20px">サーベイを開始する</Text>
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default TopPage;
