/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIPAddress = /* GraphQL */ `
  mutation CreateIPAddress(
    $input: CreateIPAddressInput!
    $condition: ModelIPAddressConditionInput
  ) {
    createIPAddress(input: $input, condition: $condition) {
      id
      address
      daytime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIPAddress = /* GraphQL */ `
  mutation UpdateIPAddress(
    $input: UpdateIPAddressInput!
    $condition: ModelIPAddressConditionInput
  ) {
    updateIPAddress(input: $input, condition: $condition) {
      id
      address
      daytime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIPAddress = /* GraphQL */ `
  mutation DeleteIPAddress(
    $input: DeleteIPAddressInput!
    $condition: ModelIPAddressConditionInput
  ) {
    deleteIPAddress(input: $input, condition: $condition) {
      id
      address
      daytime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTracking = /* GraphQL */ `
  mutation CreateTracking(
    $input: CreateTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    createTracking(input: $input, condition: $condition) {
      id
      Daytime
      IPAddress
      TrackingInfo
      Duration
      Device
      Source
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTracking = /* GraphQL */ `
  mutation UpdateTracking(
    $input: UpdateTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    updateTracking(input: $input, condition: $condition) {
      id
      Daytime
      IPAddress
      TrackingInfo
      Duration
      Device
      Source
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTracking = /* GraphQL */ `
  mutation DeleteTracking(
    $input: DeleteTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    deleteTracking(input: $input, condition: $condition) {
      id
      Daytime
      IPAddress
      TrackingInfo
      Duration
      Device
      Source
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRequestInformation = /* GraphQL */ `
  mutation CreateRequestInformation(
    $input: CreateRequestInformationInput!
    $condition: ModelRequestInformationConditionInput
  ) {
    createRequestInformation(input: $input, condition: $condition) {
      id
      CompanyName
      ContactName
      DivisionName
      Email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRequestInformation = /* GraphQL */ `
  mutation UpdateRequestInformation(
    $input: UpdateRequestInformationInput!
    $condition: ModelRequestInformationConditionInput
  ) {
    updateRequestInformation(input: $input, condition: $condition) {
      id
      CompanyName
      ContactName
      DivisionName
      Email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRequestInformation = /* GraphQL */ `
  mutation DeleteRequestInformation(
    $input: DeleteRequestInformationInput!
    $condition: ModelRequestInformationConditionInput
  ) {
    deleteRequestInformation(input: $input, condition: $condition) {
      id
      CompanyName
      ContactName
      DivisionName
      Email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConditionData = /* GraphQL */ `
  mutation CreateConditionData(
    $input: CreateConditionDataInput!
    $condition: ModelConditionDataConditionInput
  ) {
    createConditionData(input: $input, condition: $condition) {
      id
      personId
      conditionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConditionData = /* GraphQL */ `
  mutation UpdateConditionData(
    $input: UpdateConditionDataInput!
    $condition: ModelConditionDataConditionInput
  ) {
    updateConditionData(input: $input, condition: $condition) {
      id
      personId
      conditionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConditionData = /* GraphQL */ `
  mutation DeleteConditionData(
    $input: DeleteConditionDataInput!
    $condition: ModelConditionDataConditionInput
  ) {
    deleteConditionData(input: $input, condition: $condition) {
      id
      personId
      conditionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLsmData = /* GraphQL */ `
  mutation CreateLsmData(
    $input: CreateLsmDataInput!
    $condition: ModelLsmDataConditionInput
  ) {
    createLsmData(input: $input, condition: $condition) {
      id
      personId
      lsmData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLsmData = /* GraphQL */ `
  mutation UpdateLsmData(
    $input: UpdateLsmDataInput!
    $condition: ModelLsmDataConditionInput
  ) {
    updateLsmData(input: $input, condition: $condition) {
      id
      personId
      lsmData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLsmData = /* GraphQL */ `
  mutation DeleteLsmData(
    $input: DeleteLsmDataInput!
    $condition: ModelLsmDataConditionInput
  ) {
    deleteLsmData(input: $input, condition: $condition) {
      id
      personId
      lsmData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAttributeData = /* GraphQL */ `
  mutation CreateAttributeData(
    $input: CreateAttributeDataInput!
    $condition: ModelAttributeDataConditionInput
  ) {
    createAttributeData(input: $input, condition: $condition) {
      id
      personId
      attributeData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAttributeData = /* GraphQL */ `
  mutation UpdateAttributeData(
    $input: UpdateAttributeDataInput!
    $condition: ModelAttributeDataConditionInput
  ) {
    updateAttributeData(input: $input, condition: $condition) {
      id
      personId
      attributeData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAttributeData = /* GraphQL */ `
  mutation DeleteAttributeData(
    $input: DeleteAttributeDataInput!
    $condition: ModelAttributeDataConditionInput
  ) {
    deleteAttributeData(input: $input, condition: $condition) {
      id
      personId
      attributeData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeditationResultData = /* GraphQL */ `
  mutation CreateMeditationResultData(
    $input: CreateMeditationResultDataInput!
    $condition: ModelMeditationResultDataConditionInput
  ) {
    createMeditationResultData(input: $input, condition: $condition) {
      id
      personId
      meditationResultData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeditationResultData = /* GraphQL */ `
  mutation UpdateMeditationResultData(
    $input: UpdateMeditationResultDataInput!
    $condition: ModelMeditationResultDataConditionInput
  ) {
    updateMeditationResultData(input: $input, condition: $condition) {
      id
      personId
      meditationResultData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeditationResultData = /* GraphQL */ `
  mutation DeleteMeditationResultData(
    $input: DeleteMeditationResultDataInput!
    $condition: ModelMeditationResultDataConditionInput
  ) {
    deleteMeditationResultData(input: $input, condition: $condition) {
      id
      personId
      meditationResultData
      createdAt
      updatedAt
      __typename
    }
  }
`;
